import { endOfDay } from "date-fns";
import format from "date-fns/format";

export const currentDateTime = () => {
  return format(new Date(), "YMMddHHmmssSSS");
};

export const paymentAPI = () => {
  return "https://pay.tulus.my/";
};
