import React, { Fragment, useEffect, useState } from "react";
import HeaderBanner from "../../../components/HeaderBanner";
import TulusLogo from "../../../assets/images/tulus_logo.png";
import { Link, useLocation } from "react-router-dom";
import YideLogo from "../../../assets/images/yide_logo.png";
import Breadcrumbs from "../../../components/Breadcrumbs";

interface ObjectiveInfaq {
  id: number;
  image: string;
  description: string;
}

interface InfaqType {
  image: string;
  header: string;
  content: string;
  agency: string;
  type: string;
  objectives: Array<ObjectiveInfaq>;
}

interface Props {
  type: InfaqType;
}

const InfaqInfo = () => {
  const location = useLocation();

  //   const state = location.state;
  // console.log(location.state);
  const [info, setInfo] = useState<InfaqType>({
    image: "",
    header: "",
    content: "",
    agency: "",
    type: "",
    objectives: [],
  });
  // const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (location.state) {
      const stateData = location.state as Props;

      if (stateData) {
        // console.log("location?.state", stateData.type.agency);
      }

      setInfo(stateData.type);
    }
  }, [location]);

  // console.log("info", info);
  return (
    <Fragment>
      <HeaderBanner header="Infaq" />
      <Breadcrumbs
        breadCrumbItems={[
          {
            url: "/",
            name: "Utama",
          },
          {
            url: "/Infaq",
            name: "Yayasan Islam Darul Ehsan (YIDE)",
          },
          {
            url: "/WakafInfo",
            name: info.header,
          },
        ]}
      />
      <div className="flex justify-center py-12 px-3">
        <div className="p-5 md:p-10 w-100 md:w-1/2 min-h-screen bg-white rounded-lg border border-grey-200 shadow-md">
          <div>
            {info && (
              <div className="text-cyan-500 text-xl font-semibold text-center pb-3">
                {info.header}
              </div>
            )}

            <img className="h-1/3 w-full rounded-md" src={info?.image} alt="" />
            <div className="text-sm text-justify my-5">{info?.content}</div>
            <div className="flex gap-3 justify-start pt-7 pb-2">
              <Link to="/infaq/form" state={{ info }}>
                <button className="bg-cyan-700 border-sky-700 rounded-md py-2 px-3 text-white hover:bg-cyan-600 shadow-md">
                  Infaq Sekarang
                </button>
              </Link>

              <Link to="/infaq">
                <button className="bg-grey-100 border-grey-300 hover:border-grey-400 rounded-md py-2 px-5 text-sky-700 shadow-md">
                  Halaman Utama
                </button>
              </Link>
            </div>
            <div className="font-bold">
              Dengan mengklik "Infaq sekarang" anda mengesahkan bahawa anda
              memahami dan menerima
              <Link to="/dasar-privasi">
                <span className="text-blue-800 hover:text-blue-500">
                  {` Dasar Privasi `}
                </span>
              </Link>
              kami.
            </div>
            <div>
              <div className="font-bold text-2xl text-sky-800 py-7">
                Objektif Kempen
              </div>
              <div className="mx-auto grid grid-cols lg:grid-cols-3 gap-4 justify-items-center">
                {/* {objectives &&
                  objectives.map((item, i) => (
                    <div
                      className="flex flex-col shadow-md rounded-md px-5 py-3 items-center"
                      key={i}
                    >
                      <img src={item.image} alt="" className="w-2/3" />
                      <div className="text-sm text-start">
                        {item.description}
                      </div>
                    </div>
                  ))} */}
                {info.objectives &&
                  info.objectives.map((item, i) => (
                    <div
                      className="flex flex-col shadow-md rounded-md px-5 py-3 items-center border border-grey-100"
                      key={i}
                    >
                      <img src={item.image} alt="" className="w-2/3" />
                      <div className="text-sm text-start">
                        {item.description}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="font-bold text-2xl text-sky-800 py-7">
              Rakan Strategik
            </div>
            <div className="flex flex-col md:flex-row justify-start gap-3">
              <img
                src={TulusLogo}
                alt=""
                className="w-44 h-16 border border-teal-50 rounded-md px-5 py-2 shadow-sm"
              />
              <img
                src={YideLogo}
                alt=""
                className="w-44 h-16 border border-teal-50 rounded-md px-5 py-2 shadow-sm"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default InfaqInfo;
