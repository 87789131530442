import React, { Fragment } from "react";

type Props = {
  header: string;
};

const HeaderBanner = ({ header }: Props) => {
  return (
    <Fragment>
      <div className="bg-header h-32 flex items-center shadow-md">
        <div className="text-4xl font-bold text-white pl-8 md:pl-10 lg:pl-12 xl:pl-20">
          {header}
        </div>
      </div>
    </Fragment>
  );
};

export default HeaderBanner;
