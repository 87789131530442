import React from "react";
import { Link } from "react-router-dom";
import TulusLogo from "../assets/images/tulus_logo.png";

const Footer = () => {
  return (
    <>
      <div className="flex flex-col md:flex-row xl:flex-row md:justify-between h-fit text-white bg-footer bg-cover px-8 md:px-12 py-12 gap-3">
        <div className="text-left pb-5">
          <h4 className="font-bold">Dikuasakan Oleh</h4>
          <img src={TulusLogo} alt="tulus logo" className="pt-3" width={180} />
          <address className="text-sm not-italic">
            Unit 03-03-07 UOA Business Park, <br /> 1, Jalan Pengaturcara
            U1/51a,
            <br /> Section U1, <br /> 40150 Shah Alam, Selangor
          </address>
        </div>
        <div className="text-left pb-5">
          <h4 className="font-bold">Kategori</h4>
          <ul className="pt-3">
            <Link to="/zakat">
              <li className="hover:text-cyan-50">Zakat</li>
            </Link>
            <Link to="/infaq">
              <li className="hover:text-cyan-50">Infaq</li>
            </Link>
            <Link to="/wakaf">
              <li className="hover:text-cyan-50">Wakaf</li>
            </Link>
            <Link to="/sedekah">
              <li className="hover:text-cyan-50">Sedekah</li>
            </Link>
          </ul>
        </div>
        <div className="text-left pb-5">
          <h4 className="font-bold">Tulus Digital Sdn Bhd</h4>
          <Link to="/tentang-kami" className="pt-3 hover:text-cyan-50">
            Tentang Kami
          </Link>
        </div>
        <div className="text-left pb-5">
          <h4 className="font-bold">Sokongan</h4>
          <ul className="pt-3">
            <Link to="/dasar-privasi">
              <li className="hover:text-cyan-50">Dasar Privasi</li>
            </Link>
            <Link to="/soalan-lazim">
              <li className="hover:text-cyan-50">Soalan Lazim</li>
            </Link>
          </ul>
        </div>
        <div className="text-left pb-5">
          <h4 className="font-bold">Berhubung Dengan Kami</h4>
          <div className="flex flex-row pt-3">
            <a
              href="https://www.facebook.com/tulusdigital"
              target="_blank"
              className="p-2"
            >
              <svg
                className="w-6 h-6 text-white fill-current hover:text-facebook"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
              </svg>
            </a>
            <a
              href="https://twitter.com/tulusdigital?s=21&t=PMYVsoZ54G7uFO9XPW6WQQ"
              target="_blank"
              className="p-2"
            >
              <svg
                className="w-6 h-6 text-white fill-current hover:text-twitter"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
              </svg>
            </a>
            <a
              href="https://instagram.com/tulusdigital?igshid=YmMyMTA2M2Y="
              target="_blank"
              className="p-2"
            >
              <svg
                className="h-6 w-6 text-white hover:text-instagram"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                {" "}
                <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />{" "}
                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />{" "}
                <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="h-16 flex items-center place-content-center bg-download-btn text-white text-center">
        <h3 className="self-center">
          Hakcipta Terpelihara &copy; 2022 Tulus Digital Sdn Bhd No. Pendaftaran
          (930302-A)
        </h3>
      </div>
    </>
  );
};

export default Footer;
