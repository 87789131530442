import React, { Fragment, useState } from "react";
import Banner from "../components/Banner";
import ImageBanner from "../assets/images/banner/soalanlazim.jpg";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";

const accordionItems = [
  {
    id: 1,
    acheader: "Apakah itu Infaq Selangor?",
    acbody:
      "Secara ringkasnya, Infaq Selangor adalah satu laman web teknologi kewangan sosial Islamik yang menyediakan kemudahan pembayaran beretika kepada semua organisasi Islamik di Selangor. Infaq Selangor turut menggalakkan penggunaan gaya hidup secara digital dalam kalangan ummah di seluruh Selangor.",
  },
  {
    id: 2,
    acheader: "Bagaimana saya ingin mengunakan laman web Infaq Selangor?",
    acbody:
      "Laman web Infaq Selangor merupakan laman web yang mesra pengguna maka para pengguna boleh memilih untuk melakukan sumbangan derma, menunaikan zakat, wakaf atau Infaq kepada mana-mana Organisasi Islamik atau NGO pilihan mereka yang berdaftar dengan laman web Infaq Selangor di skeluruh Selangor.",
  },
  {
    id: 3,
    acheader:
      "Apakah perkhidmatan yang disediakan oleh laman web Infaq Selangor?",
    acbody:
      "Kami menawarkan perkhidmatan pantas dan selamat sepanjang tahun dengan perkhidmatan dan pembayaran berasaskan Syariah Islam seperti Zakat, Wakaf, Infaq dan sumbangan Sedekah. Infaq Selangor sedia membantu para pelanggan untuk memberikan sumbangan secara selesa dan fleksibel pada tempat dan waktu pilihan mereka. Bukan itu sahaja, kami turut menawarkan platform pembayaran yang selesa dan selamat untur para pengguna.",
  },
  {
    id: 4,
    acheader:
      "Apa beza laman web ini dengan laman web lain yang menawarkan servis yang sama?",
    acbody:
      "Kami menyediakan satu saluran bagi mengakses dan melakukan transaksi Zakat pengguna serta dapat memberikan sumbangan kepada banyak organisasi kebajikan di Selangor. Ini bermakna pengguna tidak perlu melayari laman web yang banyak dan berlainan untuk melakukan transaksi Zakat atau Infaq kebajikan di Selangor.",
  },
  {
    id: 5,
    acheader:
      "Organisasi Zakat apa yang didaftarkan dalam laman web Infaq Selangor?",
    acbody:
      "Pihak Infaq Selangor amat berbesar hati menyediakan perkhidmatan pembayaran Zakat dengan kerjasama Lembaga Zakat Selangor (LZS).",
  },
  {
    id: 6,
    acheader:
      "Adakah duit pembayaran zakat, wakaf, infaq dan sedekah boleh dipulangkan semula kepada pihak pembayar?",
    acbody:
      "Semua transaksi untuk segmen pembayaran zakat, wakaf, infaq dan sedekah tidak dapat dipulangkan setelah transaksi berjaya dilakukan.",
  },
  {
    id: 7,
    acheader:
      "Bagaimanakah saya boleh menghubungi Infaq Selangor untuk sebarang pertanyaan atau khidmat sokongan berkenaan perkhidmatan Infaq Selangor?",
    acbody:
      "Sebarang maklum balas dan pertanyaan daripada anda amatlah kami hargai. Anda boleh mengajukan sebarang pertanyaan kepada alamat emel kami di support@Infaqselangor.my dengan  menyatakan maklumat seperti nama, alamat emel yang digunakan untuk membuat transaksi FPX, ID transaksi FPX (rujuk emel daripada FPX selepas transaksi dibuat ), jumlah transaksi dan juga tarikh transaksi tersebut dibuat.",
  },
  {
    id: 8,
    acheader:
      "Bagaimanakah cara untuk mendapatkan resit rasmi bagi pembayaran melalui Infaq Selangor? ",
    acbody:
      "Setiap organisasi di bawah Infaq Selangor akan dapat resit rasmi bagi pembayaran melalui Infaq Selangor. Jika tidak mendapatkan resit, sila hubungi organisasi yang berkenaan tersebut secara terus bagi mendapatkan resit rasmi anda.",
  },
];

const FrequentlyAsked = () => {
  const [open, setOpen] = useState(0);
  const handleOpen = (value: number) => {
    setOpen(open === value ? 0 : value);
  };
  return (
    <Fragment>
      <Banner
        icon={"ssss"}
        title={"Soalan Lazim"}
        content={"Ketahui lebih lanjut mengenai Infaq Selangor"}
        image={ImageBanner}
      />

      <div className="px-5 md:px-20 py-16">
        {accordionItems &&
          accordionItems.map((item, i) => (
            <div key={i} className="text-justify">
              <Accordion
                open={open === item.id}
                onClick={() => handleOpen(item.id)}
              >
                <AccordionHeader className="text-left bg-cyan-500 p-5">
                  <div className="text-white">
                    {item.id}. {item.acheader}
                  </div>
                </AccordionHeader>
                <AccordionBody className="p-5 text-black text-base">
                  {item.acbody}
                </AccordionBody>
              </Accordion>
            </div>
          ))}
      </div>
    </Fragment>
  );
};

export default FrequentlyAsked;
