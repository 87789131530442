import React, { Fragment } from "react";
import PWSbanner from "../../../assets/images/banner/banner_pws.jpg";
import PWSLogo from "../../../assets/images/pws_logo.png";
import Banner from "../../../components/Banner";
import PWSbayar from "../../../assets/images/wakaf.png";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";

const wakafObjects = [
  {
    title: "Objektif Penubuhan",
    content: [
      {
        detail:
          "1. Membudayakan dan memudahkan Infaqan berwakaf secara sistematik dan berkesan melalui kepelbagaian produk wakaf",
      },
      {
        detail: "2. Menjamin manfaat berterusan kepada pewakaf dengan:",
        sub_detail: [
          {
            description:
              "- Memperkasakan pembangunan dan menambah nilai hartanah wakaf",
          },
          {
            description:
              "- Meningkatkan dan mengembangkan pelaburan wakaf dalam bidang ekonomi",
          },
          {
            description:
              "- Merealisasikan pembangunan sosial demi kesejahteraan Ummah",
          },
        ],
      },
      {
        detail:
          "3. Menjadikan PWS sebuah Institusi Wakaf yang sustainable, dipercayai dan berkesan",
      },
    ],
  },
  {
    title: "Fungsi PWS",
    content: [
      {
        detail:
          "1. Memberi nasihat kepada Majlis berkenaan dengan dasar, cara, langkah yang perlu dilaksanakan dan diambil untuk menggalakkan pembangunan harta dan produk wakaf",
      },
      {
        detail:
          "2. Melaksanakan dan memberi kesan kepada apa-apa dasar, arahan atau perintah Majlis berkenaan dengan pembangunan harta dan produk wakaf",
      },
      {
        detail:
          "3. Membangun, menerimapakai dan melaksanakan dasar, cara dan langkah mengenai pembangunan harta dan produk wakaf",
      },
      {
        detail:
          "4. Bertindak sebagai agensi penyelaras di dalam pelaksanaan kemajuan pembangunan harta dan produk wakaf secara pesat",
      },
      {
        detail:
          "5. Merancang, membangun, melaksana dan menggalakkan pembangunan dan pelaksanaan harta dan produk wakaf",
      },
      {
        detail:
          "6. Mengurus dan menjalankan pengendalian serta penyelenggaraan harta wakaf termasuk kemudahan, infrastruktur, tempat dan alat di kawasan harta wakaf tersebut; dan menggalakkan, menyelaraskan dan melaksanakan kajian serta pembangunan di dalam semua aspek harta dan produk wakaf",
      },
    ],
  },
  {
    title: "Berwakaf Melalui PWS",
    image: PWSbayar,
  },
];

const WakafMain = () => {
  return (
    <Fragment>
      <Link to="/zakat/form">
        <Banner
          icon={PWSLogo}
          title={"Perbadanan Wakaf Selangor"}
          content={
            "Perbadanan Wakaf Selangor berharap agar dapat melaksanakan kebajikan kepada masyarakat di negeri Selangor khususnya dan Malaysia amnya dengan kerjasama dan sokongan padu daripada pihak-pihak yang prihatin bagi memastikan objektif-objektif penubuhannya tercapai."
          }
          image={PWSbanner}
          zakat="Bayar Wakaf"
        />
      </Link>
      <div>
        <Breadcrumbs
          breadCrumbItems={[
            {
              url: "/",
              name: "Utama",
            },
            {
              url: "/WakafMain",
              name: "Wakaf",
            },
          ]}
        />
      </div>

      <div className="mx-auto px-5 md:px-12 lg:px-16 pb-20">
        {wakafObjects.map((wakaf, i) => (
          <div className="my-10 font-bold" key={i}>
            <div className="box-border w-full md:w-5/6">
              <h1 className="text-lg md:text-3xl text-cyan-500 pb-2 md:pb-5">
                {wakaf.title}
              </h1>
              {wakaf.content &&
                wakaf.content.map((cont, j) => (
                  <ul className="font-normal leading-loose" key={j}>
                    <div className="text-justify">
                      <div className="">{cont.detail}</div>
                      <div>
                        {cont.sub_detail &&
                          cont.sub_detail.map((item, k) => (
                            <div key={k} className="ml-5">
                              {item.description}
                            </div>
                          ))}
                      </div>
                    </div>
                  </ul>
                ))}
              <div className="text-base font-normal">
                Jalankan tanggungjawab bersama! Wakaf kini dihujung jari dengan
                Infaq Selangor! Klik Link Di Bawah Sekarang!
              </div>
            </div>

            <Link to="/wakaf/info">
              <img
                src={wakaf.image}
                alt=""
                className="w-80 hover:scale-105 ease-in-out duration-300 py-5"
              />
            </Link>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default WakafMain;
