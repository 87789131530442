import React from "react";
import { useRoutes } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import About from "../views/About";
import InfaqForm from "../views/category/infaq/InfaqForm";
import InfaqInfo from "../views/category/infaq/InfaqInfo";
import InfaqMain from "../views/category/infaq/InfaqMain";
import WakafForm from "../views/category/pws/WakafForm";
import WakafInfo from "../views/category/pws/WakafInfo";
import WakafMain from "../views/category/pws/WakafMain";
import SedekahForm from "../views/category/sedekah/SedekahForm";
import SedekahInfo from "../views/category/sedekah/SedekahInfo";
import SedekahMain from "../views/category/sedekah/SedekahMain";
import ZakatForm from "../views/category/zakat/ZakatForm";
import ZakatMain from "../views/category/zakat/ZakatMain";
import ContactUs from "../views/ContactUs";
import FrequentlyAsked from "../views/FrequentlyAsked";
import Home from "../views/Home";
import PrivacyPolicy from "../views/PrivacyPolicy";
import Return from "../views/Return";

export default function Router() {
  let element = useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/soalan-lazim",
          element: <FrequentlyAsked />,
        },
        {
          path: "/tentang-kami",
          element: <About />,
        },
        {
          path: "/dasar-privasi",
          element: <PrivacyPolicy />,
        },
        {
          path: "/hubungi-kami",
          element: <ContactUs />,
        },
        {
          path: "/zakat",
          element: <ZakatMain />,
        },
        {
          path: "zakat/form",
          element: <ZakatForm />,
        },
        {
          path: "/wakaf",
          element: <WakafMain />,
        },
        {
          path: "/wakaf/info",
          element: <WakafInfo />,
        },
        {
          path: "/wakaf/form",
          element: <WakafForm />,
        },
        {
          path: "/infaq",
          element: <InfaqMain />,
        },
        {
          path: "/infaq/info",
          element: <InfaqInfo />,
        },
        {
          path: "/infaq/form",
          element: <InfaqForm />,
        },
        {
          path: "/sedekah",
          element: <SedekahMain />,
        },
        {
          path: "sedekah/info",
          element: <SedekahInfo />,
        },
        {
          path: "sedekah/form",
          element: <SedekahForm />,
        },
        {
          path: "fpx/return/:refNo",
          element: <Return />,
        },
      ],
    },
  ]);

  return element;
}
