import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderBanner from "../../../components/HeaderBanner";
import { currentDateTime } from "../../../utils";
import Breadcrumbs from "../../../components/Breadcrumbs";

interface FormData {
  email: string;
  no_ic: string;
  name: string;
  phone_no: string;
  amount: number;
  agency: string; //WK-00002
  returnUrl: string;
  refNo: string;
}

const generate_returnurl = (refNo: string): string => {
  let host: string = "http://localhost:12120";
  if (!/localhost/.test(window.location.host)) {
    host = window.location.protocol + "//" + window.location.host;
  }
  return refNo !== "" ? `${host}/api/return/fpx/${refNo}` : "";
};

const generate_extradata = (
  payer: FormData,
  extraData: object | null
): string => {
  let returnURL = payer.refNo;
  if (returnURL !== "" && extraData !== null) {
    returnURL = `${returnURL}~${JSON.stringify(extraData)}`;
  }
  return returnURL;
};

const WakafForm = () => {
  const [payer, setPayer] = useState<FormData>({
    email: "",
    no_ic: "",
    name: "",
    phone_no: "",
    amount: 10,
    agency: "WK-00002",
    returnUrl: "",
    refNo: "",
  });

  const [error, setError] = useState<any>({
    email: "",
    amount: "",
    refNo: "",
    phone_no: "",
    name: "",
    no_ic: "",
  });

  const [btnDisabled, setBtnDisabled] = useState(false);

  // const [refNo, setRefNo] = useState<string>("");

  const navigate = useNavigate();

  const emelRegex = RegExp(
    /^\s?[A-Z0–9]+[A-Z0–9._+-]{0,}@[A-Z0–9._+-]+\.[A-Z0–9]{2,4}\s?$/i
  );

  const numberRegex = RegExp(/^\d+$/i);

  const onChangePayer = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    let newRefNo;

    let newError: any = JSON.parse(JSON.stringify(error));

    if (name === "email") {
      if (!emelRegex.test(value)) {
        newError.email = "Emel tidak sah";
        setBtnDisabled(false);
      } else if (value !== "" && value.trim().length === 0) {
        newError.email = "Diperlukan";
        setBtnDisabled(false);
      } else {
        newError.email = null;
        setBtnDisabled(true);
      }
    }

    if (name === "no_ic") {
      if (!numberRegex.test(value)) {
        newError.no_ic = "Pastikan hanya nombor yang sah dimasukkan";
        setBtnDisabled(false);
      } else if (value !== "" && value.trim().length === 0) {
        newError.no_ic = "Diperlukan!";
        setBtnDisabled(false);
      } else if (value !== "" && value.trim().length < 12) {
        newError.no_ic = "Nombor aksara kurang daripada 12";
        setBtnDisabled(false);
      } else if (value !== "" && value.trim().length > 12) {
        newError.no_ic = "Nombor aksara lebih daripada 12";
        setBtnDisabled(false);
      } else {
        newError.no_ic = null;
        setBtnDisabled(true);
      }
    }

    if (name === "name") {
      if (value !== "" && value.trim().length === 0) {
        newError.name = "Diperlukan";
        setBtnDisabled(false);
      } else if (value !== "" && value.trim().length < 5) {
        newError.name = "Nama kurang daripada 5 aksara";
        setBtnDisabled(false);
      } else {
        newError.name = null;
        setBtnDisabled(true);
      }
    }

    if (name === "phone_no") {
      if (value !== "" && value.trim().length === 0) {
        newError.phone_no = "Diperlukan";
        setBtnDisabled(false);
      } else if (!numberRegex.test(value)) {
        newError.phone_no = "Nombor telefon tidak sah";
        setBtnDisabled(false);
      } else if (value !== "" && value.trim().length < 10) {
        newError.phone_no = "Nombor telefon kurang daripada 10 nombor aksara";
        setBtnDisabled(false);
      } else if (value !== "" && value.trim().length > 11) {
        newError.phone_no = "Nombor telefon lebih daripada 11 nombor aksara";
        setBtnDisabled(false);
      } else {
        newError.phone_no = null;
        setBtnDisabled(true);
      }
    }

    if (name == "amount") {
      if (value !== "" && value < 10) {
        newError.amount = "Minimum amaun RM 10";
        setBtnDisabled(false);
      } else if (!numberRegex.test(value)) {
        newError.amount = "Amaun tidak sah";
        setBtnDisabled(false);
      } else {
        newError.amount = null;
        setBtnDisabled(true);
      }
    }

    const newPayer = { ...payer, [name]: value, refNo: currentDateTime() };

    setPayer({ ...newPayer });

    // newRefNo =
    //   currentDateTime() +
    //   "~" +
    //   JSON.stringify({
    //     email: newPayer.email,
    //     name: newPayer.name,
    //     no_ic: newPayer.no_ic,
    //     phone_no: newPayer.phone_no,
    //   });

    // console.log("newRefNo", newRefNo);
    // setRefNo(newRefNo);
    setError(newError);
  };

  let extraData = {
    email: payer.email,
    name: payer.name,
    no_ic: payer.no_ic,
    phone_no: payer.phone_no,
  };

  return (
    <Fragment>
      <HeaderBanner header="Wakaf" />
      <Breadcrumbs
        breadCrumbItems={[
          {
            url: "/",
            name: "Utama",
          },
          {
            url: "/Wakaf",
            name: "Wakaf",
          },
          {
            url: "/WakafForm",
            name: "Bayar Wakaf",
          },
        ]}
      />
      <div className="flex justify-center my-10 px-5">
        <div className="border border-grey-200 rounded-md shadow-sm w-full md:w-5/6 lg:w-9/12">
          <form className="m-5" action="https://pay.tulus.my" method="post">
            <div className="text-2xl text-cyan-500 font-semibold py-2">
              Wakaf Tunai <br />
              <span className="text-sm text-cyan-900">
                *Sila isi semua maklumat yang dikehendaki untuk menunaikan wakaf
                anda
              </span>
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label
                htmlFor="email"
                className="text-sm font-bold text-gray-800"
              >
                Emel <span className="text-red-600">*</span>
              </label>

              <div className="w-full md:w-9/12">
                <input
                  type="text"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full xl:w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
                  placeholder="example@example.com"
                  name="email"
                  value={payer.email}
                  onChange={onChangePayer}
                  required
                />
                <span className="text-red-600">{error.email}</span>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label
                htmlFor="no_ic"
                className="text-sm font-bold text-gray-800"
              >
                No Kad. Pengenalan <span className="text-red-600">*</span>
              </label>

              <div className="w-full md:w-9/12">
                <input
                  type="text"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full xl:w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
                  placeholder="No. Kad Pengelanan"
                  name="no_ic"
                  value={payer.no_ic}
                  onChange={onChangePayer}
                  required
                />
                <span className="text-red-600">{error.no_ic}</span>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label htmlFor="name" className="text-sm font-bold text-gray-800">
                Nama Penuh <span className="text-red-600">*</span>
              </label>

              <div className="w-full md:w-9/12">
                <input
                  type="text"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full xl:w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
                  placeholder="Nama Penuh"
                  name="name"
                  value={payer.name}
                  onChange={onChangePayer}
                  required
                />
                <span className="text-red-600">{error.name}</span>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label
                htmlFor="phone_no"
                className="text-sm font-bold text-gray-800"
              >
                Nombor Telefon <span className="text-red-600">*</span>
              </label>

              <div className="w-full md:w-9/12">
                <input
                  type="text"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full xl:w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
                  placeholder="Nomber Telefon"
                  name="phone_no"
                  value={payer.phone_no}
                  onChange={onChangePayer}
                  required
                />
                <span className="text-red-600">{error.phone_no}</span>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label
                htmlFor="amount"
                className="text-sm font-bold text-gray-800"
              >
                Amaun <span className="text-red-600">*</span>
              </label>
              <div className="w-full md:w-9/12">
                <input
                  type="text"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
                  placeholder="Amaun"
                  name="amount"
                  value={payer.amount}
                  onChange={onChangePayer}
                  required
                />
                <span className="text-red-600">{error.amount}</span>
              </div>
            </div>
            <input
              type="hidden"
              className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full lg:w-9/12 shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
              placeholder=""
              name="refNo"
              value={generate_extradata(payer, extraData)}
              onChange={onChangePayer}
            />
            <input
              type="hidden"
              className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full lg:w-9/12 shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
              placeholder=""
              name="returnUrl"
              value={generate_returnurl(payer.refNo)}
              onChange={onChangePayer}
            />
            <input
              type="hidden"
              className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full lg:w-9/12 shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
              placeholder=""
              name="agency"
              value={payer.agency}
              onChange={onChangePayer}
            />
            <div className="flex flex-col md:flex-row justify-evenly py-5 gap-4">
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="border border-cyan-600 w-full md:w-3/6 lg:w-1/3 rounded-md p-2 self-center text-center bg-white hover:bg-gray-100 hover:scale-105 transition duration-300 ease-out hover:ease-in"
              >
                Kembali
              </button>

              <button
                disabled={!btnDisabled}
                type="submit"
                className="border border-cyan-600 w-full md:w-3/6 lg:w-1/3 rounded-md p-2 self-center text-center bg-cyan-600 hover:bg-cyan-700 text-white hover:text-black hover:scale-105 transition duration-300 ease-out hover:ease-in disabled:opacity-75"
              >
                Bayar
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default WakafForm;
