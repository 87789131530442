import React, { Fragment } from "react";
import HeaderBanner from "../../../components/HeaderBanner";
import { Link } from "react-router-dom";
import TulusLogo from "../../../assets/images/tulus_logo.png";
import PWSLogo from "../../../assets/images/pws_logo.png";
import Breadcrumbs from "../../../components/Breadcrumbs";

const wakafInfo = [
  {
    id: 1,
    description:
      "Nilai asal wang yang diwakafkan hendaklah sentiasa dikembangkan dan meningkat",
  },
  {
    id: 2,
    description: "Sebarang pembelian aset hendaklah menepati hukum syarak",
  },
  {
    id: 3,
    description:
      "Lain-lain syarat yang ditetapkan dari semasa ke semasa oleh PWS",
  },
];

const wakafObjective = [
  {
    id: 1,
    description:
      "Menyuburkan semula Infaqan wakaf yang merupakan sedekah jariah yang sangat dituntut oleh Islam",
  },
  {
    id: 2,
    description:
      "Menyediakan kemudahan dan alternatif kepada masyarakat Islam untuk menyertai ibadah wakaf walaupun tanpa melibatkan aset kekal",
  },
  {
    id: 3,
    description:
      "Memperluas dan memperbanyakkan lagi pegangan harta umat Islam, hasil kutipan wakaf tunai akan dijadikan modal untuk membangunkan harta-harta wakaf atau pembelian hartanah yang sedang/telah dibangunkan serta manfaatnya akan disalurkan untuk kebajikan dan pembangunan masyarakat Islam",
  },
  {
    id: 4,
    description:
      "Usaha untuk menggalakkan masyarakat mengiktiraf wakaf sebagai sistem yang diyakini berpotensi untuk menggerakkan pembangunan sosial dan ekonomi masyarakat Islam",
  },
  {
    id: 5,
    description:
      "Menanam dan mendidik semangat bekerjasama antara umat Islam yang berteraskan konsep taa’wun dan ukhuwah Islamiah kerana dengan konsep persepakatan ini dapat membantu menggerakkan sosial dan ekonomi umat Islam.",
  },
];

const WakafInfo = () => {
  return (
    <Fragment>
      <HeaderBanner header="Wakaf" />

      <Breadcrumbs
        breadCrumbItems={[
          {
            url: "/",
            name: "Utama",
          },
          {
            url: "/Wakaf",
            name: "Wakaf",
          },
          {
            url: "/WakafInfo",
            name: "Wakaf Tunai",
          },
        ]}
      />

      <div className="flex justify-center py-12 px-3">
        <div className="p-5 md:p-10 w-100 md:w-1/2 min-h-screen bg-white rounded-lg border border-grey-200 shadow-md">
          <div>
            <div className="text-sky-800 text-3xl font-semibold text-center pb-3">
              Wakaf Tunai
            </div>
            <div className="text-xl text-cyan-500 font-semibold">
              Maklumat Wakaf Tunai
            </div>
            <div className="text-justify text-base">
              Merujuk kepada keputusan Mesyuarat Jawatankuasa Fatwa Negeri
              Selangor Kali Ke-5/2017 yang bersidang pada 17 Oktober 2107
              bersamaan 26 Muharram 1438H memutuskan bahawa:
            </div>
            <div className="font-semibold text-base py-5">
              Harus melaksanakan wakaf tunai sebagai harta wakaf dengan
              syarat-syarat berikut:
            </div>
            {wakafInfo.map((info, i) => (
              <div key={i} className="text-base">
                <div>
                  {info.id}. {info.description}
                </div>
              </div>
            ))}
            <div className="flex gap-3 justify-start pt-7 pb-2">
              <Link to="/wakaf/form">
                <button className="bg-cyan-700 border-sky-700 rounded-md py-2 px-3 text-white hover:bg-cyan-600 shadow-md">
                  Wakaf Sekarang
                </button>
              </Link>

              <Link to="/wakaf">
                <button className="bg-grey-100 border-grey-300 hover:border-grey-400 rounded-md py-2 px-5 text-sky-700 shadow-md">
                  Kembali
                </button>
              </Link>
            </div>
            <div className="font-bold">
              Dengan mengklik "Wakaf sekarang" anda mengesahkan bahawa anda
              memahami dan menerima
              <Link to="/dasar-privasi">
                <span className="text-blue-800 hover:text-blue-500">
                  {` Dasar Privasi `}
                </span>
              </Link>
              kami.
            </div>
            <div className="text-2xl font-semibold text-sky-800 py-5">
              Objektive Kempen
            </div>
            {wakafObjective.map((objective, i) => (
              <div key={i} className="text-base">
                <div className="py-2">
                  {objective.id}. {objective.description}
                </div>
              </div>
            ))}
            <div className="font-bold text-2xl text-sky-800 py-7">
              Rakan Strategik
            </div>
            <div className="flex flex-col md:flex-row justify-start gap-3">
              <img
                src={TulusLogo}
                alt=""
                className="w-44 h-16 border border-teal-50 rounded-md px-5 py-2 shadow-sm"
              />
              <img
                src={PWSLogo}
                alt=""
                className="w-44 h-16 border border-teal-50 rounded-md px-5 py-2 shadow-sm"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WakafInfo;
