import React, { Fragment } from "react";
import Banner from "../components/Banner";
import ImageBanner from "../assets/images/banner/tentangkami.jpg";
import MudahLogo from "../assets/images/icon/mudah.svg";
import TelusLogo from "../assets/images/icon/telus.svg";
import SelamatLogo from "../assets/images/icon/selamat.svg";
import PantasLogo from "../assets/images/icon/pantas.svg";

const items = [
  {
    id: 1,
    image: MudahLogo,
    title: "Mudah",
    content:
      "Infaq Selangor sangat mudah untuk digunakan dan difahami Oleh pengguna. Ia juga memudahkan pengguna untuk berInfaq di atas talian.",
  },
  {
    id: 1,
    image: PantasLogo,
    title: "Pantas",
    content:
      "Pengguna dapat menghulurkan bantuan kepada yang memerlukan dengan lebih pantas melalui Infaq Selangor.",
  },
  {
    id: 1,
    image: TelusLogo,
    title: "Telus",
    content:
      "Platform Infaq Selangor bekerjasama dengan agensi yang dipercayai. Pengguna boleh menyalurkan bantuan tanpa keraguan.",
  },
  {
    id: 1,
    image: SelamatLogo,
    title: "Selamat",
    content:
      "Pengguna tidak perlu Khuatir kerana segala pembayaran dijamin selamat dengnan menggunakan pembayaran FPX.",
  },
];

console.log(items);

const About = () => {
  return (
    <Fragment>
      <Banner
        icon={""}
        title={"Tentang Kami"}
        content={"Kami Bersedia membantu anda"}
        image={ImageBanner}
      />

      <div className="py-16 px-5 md:px-20">
        <div className="text-cyan-500 font-semibold text-2xl">
          Tentang Kami (Infaq Selangor)
        </div>
        <div className="py-5">
          Infaq Selangor adalah inisiatif Tulus Digital bagi menggabungkan
          agensi luar bagi membantu dan menyantuni serta pembangunan ummah
          negeri Selangor. Ia terdiri daripada zakat, sedekah dan wakaf daripada
          organisasi yang terlibat seperti Yayasan Islam Darul Ehsan (YIDE),
          Perbadanan Wakaf Selangor (PWS), dan Lembaga Zakat Selangor (LZS).
          Infaq Selangor adalah merupakan satu platform digital bagi memudahkan
          ‘crowd funding’ dimana mampu membantu mengurangkan beban serta
          mengeratkan hubungan penduduk Selangor di setiap golongan yang
          memerlukan bantuan. Infaq Selangor bekerjasama dengan syarikat Tulus
          Digital bagi berkongsi rezeki untuk menyempurnakan kehidupan penduduk
          Selangor serta dapat membantu golongan yang tidak berkemampuan.
        </div>
        <div className="text-cyan-500 font-semibold text-2xl py-10">
          Kenapa anda perlu berInfaq bersama kami
        </div>
        <div className="grid grid-cols md:grid-cols-2 lg:grid-cols-4 gap-4">
          {items &&
            items.map((item, i) => (
              <div className="flex flex-col items-center" key={i}>
                <div className="rounded-full">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-36 h-36"
                  />
                </div>
                <div className="py-5 font-semibold">{item.title}</div>
                <div className="text-center">{item.content}</div>
              </div>
            ))}
        </div>
      </div>
    </Fragment>
  );
};

export default About;
