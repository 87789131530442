import React, { Fragment } from "react";
import Banner from "../components/Banner";
import ImageBanner from "../assets/images/banner/hubungikami.jpg";
import JoinUsImage from "../assets/images/banner/sertaikami.png";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <Fragment>
      <Banner
        icon={""}
        title={"Hubungi Kami"}
        content={"Kami Bersedia membantu anda"}
        image={ImageBanner}
      />

      <div className="px-5 md:px-20 py-16">
        <a href="https://tulus.my/sertai-kami" target="_blank">
          <img src={JoinUsImage} alt="" />
        </a>

        <div className="grid grid-cols md:grid-cols-1 lg:grid-cols-2 gap-4 py-10">
          <div>
            <div className="text-cyan-500 font-semibold text-2xl">
              Hubungi Kami
            </div>
            <div className="flex text-cyan-500 items-center py-5">
              <span className="pr-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                  />
                </svg>
              </span>

              <span className="text-xl">Alamat</span>
            </div>
            <address>
              Unit 03-03-07 UOA Business Park, 1,
              <br /> Jalan Pengaturcara U1/51a, <br />
              Section U1, 40150 Shah Alam, Selangor
            </address>
            <div className="flex text-cyan-500 items-center py-5">
              <span className="pr-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </span>
              <span className="text-xl">Email</span>
            </div>
            <div>info@infaqselangor.com</div>
            <div className="flex text-cyan-500 items-center py-5">
              <span className="pr-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
              </span>
              <span className="text-xl">No. Telefon</span>
            </div>
            <div>
              <a href="tel:+032121234">+603-55670103</a>
            </div>
          </div>
          <div>
            <div className="text-cyan-500 font-semibold text-2xl">
              Lokasi Kami
            </div>
            {/* <div className="rounded-md border p-1 border-grey-300"> */}
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.0319591595267!2d101.58487941475717!3d3.0861450977526705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4c38b5fda6ef%3A0x4ae6ca25a3d8e5ff!2sTuxuri%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1659082512987!5m2!1sen!2smy"
              width="600"
              height="450"
              //   style="border:0;"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="rounded-md shadow-sm border border-grey-400"
            ></iframe> */}
            <div className="w-full border border-grey-200 p-4 rounded-md">
              <iframe
                width="100%"
                height="600"
                frameBorder="0"
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Tuxuri,%20Unit%203-12-09%20UOA%20Business%20Park,%201,%20Jalan%20Pengaturcara%20U1/51a,%20Section%20U1,%2040150%20Shah%20Alam,%20Selangor+(Tulus%20Digital%20Sdn%20Bhd)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              >
                <a href="https://www.maps.ie/distance-area-calculator.html">
                  area maps
                </a>
              </iframe>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ContactUs;
