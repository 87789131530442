import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

type Props = {
  icon: any;
  title: string;
  content: string;
  image: string;
  zakat?: string;
  scroll?: string;
  test?: string;
};

const Banner = ({ icon, title, content, image, zakat, scroll }: Props) => {
  // const handleButtonClick = () => {
  //   window.scrollTo({
  //     top: document.body.scrollHeight,
  //     behavior: "smooth",
  //   });
  // };

  // const handleScrollVisibility = () => {
  //   setShowScrollButton(window.scrollY > 0);
  // };
  const handleButtonClick = () => {
    // const section = document.getElementById("jenis-pilihan-infaq");
    const section = document.getElementById("content_3");
    console.log(section);
    if (section) {
      const sectionOffset = Math.abs(
        section.getBoundingClientRect().top - window.scrollY
      );

      window.scrollTo({
        top: sectionOffset,
        behavior: "smooth",
      });
    }
  };

  // useLayoutEffect(() => {
  //   window.addEventListener("scroll", handleScrollVisibility);

  //   return () => {
  //     window.removeEventListener("scroll", handleScrollVisibility);
  //   };
  // }, []);

  return (
    <Fragment>
      <div className="relative">
        <div className="absolute inset-0 z-10 flex">
          <div className="absolute inset-0 bg-black opacity-60"></div>
          <div className="mt-5 ml-5 md:mt-10 md:ml-10 lg:ml-16 z-10 w-9/12 md:w-2/3">
            <img className="w-20 md:w-36" src={icon} alt="" />
            <h1 className="text-white text-sm md:text-base lg:text-4xl font-bold">
              {title}
            </h1>
            <p className="text-white text-justify pt-5 text-xs md:text-base lg:text-xl box-border">
              {content}
            </p>

            {zakat ? (
              <div className="flex">
                <Link to="/zakat/form">
                  <div className="flex justify-between items-center bg-opacity-0 px-2 md:px-5 py-2 md:py-3 mt-4 rounded-2xl border-2 border-yellow-500 text-yellow-500 inline-block shadow-sm hover:scale-105 hover:border-white hover:text-white w-full text-xs">
                    <p className="mr-12 px-4 ">{zakat}</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </Link>
              </div>
            ) : null}

            {/* {scroll && scroll.length > 0 ? ( */}
            <div className="flex">
              {scroll && (
                <button
                  onClick={handleButtonClick}
                  className="flex justify-center items-center bg-opacity-0 p-1 md:p-3 mt-1 md:mt-4 rounded-full border-2 border-yellow-500 text-yellow-500 inline-block shadow-sm hover:scale-105 hover:border-white hover:text-white"
                >
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-3 md:h-3 md:w-3"
                    viewBox="0 0 52 52"
                    enableBackground="new 0 0 52 52"
                  >
                    <path
                      d="M9.6,31c-0.8,0.8-0.8,1.9,0,2.7l15,14.7c0.8,0.8,2,0.8,2.8,0l15.1-14.7c0.8-0.8,0.8-1.9,0-2.7l-2.8-2.7
	                    c-0.8-0.8-2-0.8-2.8,0l-4.7,4.6C31.4,33.7,30,33.2,30,32V5c0-1-0.9-2-2-2h-4c-1.1,0-2,1.1-2,2v27c0,1.2-1.4,1.7-2.2,0.9l-4.7-4.6
	                    c-0.8-0.8-2-0.8-2.8,0L9.6,31z"
                    />
                  </svg>
                </button>
              )}
            </div>
            {/* <div>
              <Breadcrumbs
                breadCrumbItems={[
                  {
                    url: "/Home.tsx",
                    name: "Utama",
                  },
                  {
                    url: "/ZakatMain.tsx",
                    name: "Lembaga Zakat Selangor (LZS)",
                  },
                ]}
              />
            </div> */}
          </div>
        </div>
        <img className="w-full h-64 md:h-96" src={image} alt="" />
      </div>
      {/* <div>
        <Breadcrumbs
          breadCrumbItems={[
            {
              url: "/Home",
              name: "Utama",
            },
            {
              url: "/ZakatMain",
              name: "Lembaga Zakat Selangor (LZS)",
            },
          ]}
        />
      </div> */}
    </Fragment>
  );
};

export default Banner;
