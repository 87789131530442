import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import InfaqLogo from "../assets/images/icon/infaq_logo.svg";
import HeaderBanner from "../components/HeaderBanner";

interface FPXResponse {
  id: number;
  status: string;
  order_no: string;
  ref_no: string;
  amount: number;
  fpx_transaction_id: string;
  add_charge: string;
  agency: string;
  extra_data: string;
}

const Return = () => {
  const [data, setData] = useState<FPXResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { refNo } = useParams();

  useEffect(() => {
    let ignore = false;
    const getData = async () => {
      setLoading(true);
      fetch(`/api/test/fpx/${refNo}`)
        .then((res) => res.json())
        .then((res) => {
          if (!ignore) {
            setData(res);
            setError(null);
            setLoading(false);
          }
        })
        .catch((err) => {
          setError(err);
          setData(undefined);
        });
    };
    if (refNo !== "" && typeof data === "undefined") {
      getData();
    }
    return () => {
      ignore = true;
    };
  }, [refNo]);

  return (
    <Fragment>
      <HeaderBanner header="Resit" />
      <div className="flex flex-col items-center py-12 px-3">
        {loading && (
          <button
            className="flex items-center bg-download-btn px-4 py-2 text-white rounded-lg"
            disabled
          >
            <img
              src={InfaqLogo}
              alt=""
              className="animate-spin w-6 h-6 rounded-full mr-3"
            />
            <span className="text-base font-medium">Processing...</span>
          </button>
        )}
        {error && <div>{`There are problem fetching data - ${error}`}</div>}

        {data && (
          <div className="p-5 md:p-10 w-100 md:w-1/2 bg-white rounded-lg border border-grey-200 shadow-md">
            <div className="flex items-center justify-center flex-col-reverse gap-5 py-5">
              <div className="text-2xl font-semibold">
                Resit Pembayaran Sementara
              </div>

              <img
                src={`https://assets.tulus.my/images/${data.agency}.jpg`}
                alt=""
                className="w-56"
              />
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label className="text-sm font-bold text-gray-800">
                Status Transaksi FPX
              </label>

              <div className="w-9/12">
                <input
                  type="text"
                  className="bg-grey-100 rounded pl-3 py-2 w-full xl:w-full shadown-sm placeholder-gray-400 text-gray-800"
                  value={data.status}
                  disabled
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label className="text-sm font-bold text-gray-800">
                ID Transaksi FPX
              </label>

              <div className="w-9/12">
                <input
                  type="text"
                  className="bg-grey-100 rounded pl-3 py-2 w-full xl:w-full shadown-sm placeholder-gray-400 text-gray-800"
                  value={data.order_no}
                  disabled
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label className="text-sm font-bold text-gray-800">
                Seller Order Number
              </label>

              <div className="w-9/12">
                <input
                  type="text"
                  className="bg-grey-100 rounded pl-3 py-2 w-full xl:w-full shadown-sm placeholder-gray-400 text-gray-800"
                  value={data.fpx_transaction_id}
                  disabled
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label className="text-sm font-bold text-gray-800">
                No. Rujukan
              </label>

              <div className="w-9/12">
                <input
                  type="text"
                  className="bg-grey-100 rounded pl-3 py-2 w-full xl:w-full shadown-sm placeholder-gray-400 text-gray-800"
                  value={data.ref_no}
                  disabled
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label className="text-sm font-bold text-gray-800">Amaun</label>

              <div className="w-9/12">
                <input
                  type="text"
                  className="bg-grey-100 rounded pl-3 py-2 w-full xl:w-full shadown-sm placeholder-gray-400 text-gray-800"
                  value={data.amount}
                  disabled
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label className="text-sm font-bold text-gray-800">Agensi</label>

              <div className="w-9/12">
                <input
                  type="text"
                  className="bg-grey-100 rounded pl-3 py-2 w-full xl:w-full shadown-sm placeholder-gray-400 text-gray-800"
                  value={data.agency}
                  disabled
                />
              </div>
            </div>
          </div>
        )}
        <Link
          to="/"
          className="bg-download-btn rounded-md shadow-sm px-5 py-3 text-white w-1/2 my-10 hover:bg-cyan-800 text-center"
        >
          Kembali
        </Link>
      </div>
    </Fragment>
  );
};

export default Return;
