import React, { Fragment } from "react";
import Zakat from "../assets/images/Zakat.jpg";
import Sedekah from "../assets/images/Sedekah.jpg";
import Infaq from "../assets/images/Infaq.jpg";
import Wakaf from "../assets/images/Wakaf.jpg";
import LZSlogo from "../assets/images/lzs_logo.png";
import PWSlogo from "../assets/images/pws_logo.png";
import YideLogo from "../assets/images/yide_logo.png";
import GooglePlayBadge from "../assets/images/google-play-badge.png";
import AppStoreBadge from "../assets/images/app-store-badge.png";
import Hero from "../components/Hero";
import { Link } from "react-router-dom";
import AppMockup from "../assets/images/app_mockup.png";

const categories = [
  {
    name: "Zakat",
    image: Zakat,
    detail:
      "Tunaikan zakat dengan mudah sekarang untuk membantu golongan asnaf di Selangor.",
    path: "/zakat",
  },
  {
    name: "Infaq",
    image: Infaq,
    detail: "Berinfaq merapatkan jurang antara yang kaya dan miskin.",
    path: "/infaq",
  },
  {
    name: "Wakaf",
    image: Wakaf,
    detail:
      "Laksanakan ibadah wakaf dengan perbadanan wakaf yang dipercayai di sini sekarang.",
    path: "/wakaf",
  },
  {
    name: "Sedekah",
    image: Sedekah,
    detail:
      "Bantu saudara kita di Selangor dengan memberi sedikit sumbangan di mana-mana sahaja.",
    path: "/sedekah",
  },
];

const agenciesIcon = [
  {
    name: "Lembaga Zakat Selangor",
    image: LZSlogo,
  },
  {
    name: "Perbadanan Wakaf Selangor",
    image: PWSlogo,
  },
  {
    name: "Yayasan Islam Darul Ehsan",
    image: YideLogo,
  },
];

const Home = () => {
  return (
    <Fragment>
      <Hero />
      <div className="container md:mx-auto px-3">
        <div className="pt-16">
          <div className="text-4xl font-bold text-cyan-500">
            Pilihan Kategori
          </div>
          <div className="pt-2 pb-7 font-medium">
            Berikut adalah kategori yang terdapat dalam Infaq Selangor yang
            dapat membantu anda bagi berInfaq dengan mudah! Klik kategori yang
            menjadi pilihan anda untuk maklumat lanjut.
          </div>
        </div>

        <div className="container mx-auto grid grid-cols md:grid-cols-2 lg:grid-cols-4 gap-4">
          {categories.map((category, id) => (
            <div
              key={id}
              className="bg-white rounded-lg border-gray-200 shadow-md md:m-0 overflow-hidden flex"
            >
              <Link to={category.path} className="flex flex-col">
                <img className="rounded-t-xl" src={category.image} alt="" />
                <div className="p-3 flex flex-col flex-1">
                  <div className="mb-2 text-2xl font-bold tracking-tight text-grey-900">
                    {category.name}
                  </div>
                  <div className="text-gray-500 font-normal flex-1 py-3">
                    {category.detail}
                  </div>
                  <div className="flex items-center justify-between text-center text-white bg-cyan-500 px-5 py-3 rounded-md shadow-md hover:scale-105 ease-in-out duration-300">
                    <span className="">Lebih Lanjut</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-16 px-3">
          <div>
            <h1 className="text-4xl font-bold pt-5 text-cyan-500 pb-5">
              Agensi Selangor
            </h1>
            <p className="text-gray-500 font-medium">
              Infaq Selangor telah menjalinkan kerjasama pintar dengan
              agensi-agensi utama dalam pembangunan institusi Wakaf, Zakat dan
              Infaq. Kerjasama dengan agensi yang terlibat ini menguruskan
              pembayaran berteraskan konsep Islami serta patuh shariah.
            </p>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center gap-5 md:gap-3 pt-5">
            {agenciesIcon &&
              agenciesIcon.map((agency, i) => (
                <div
                  className="border rounded-md border-cyan-500 px-5 py-3"
                  key={i}
                >
                  <img
                    src={agency.image}
                    alt={agency.name}
                    className="w-64 h-20 md:w-96 md:h-16"
                  />
                </div>
              ))}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-16 px-3">
          <div>
            <h1 className="text-4xl font-bold text-cyan-500 pb-5">
              Muat Turun Aplikasi <br /> Tulus Sekarang!
            </h1>
            <p className="text-gray-500 font-medium pt-3">
              Capaian pantas dan selamat sepanjang tahun kepada perkhidmatan dan
              urusan pembayaran berteraskan Islami serta patuh syariah.
            </p>
            <div className="flex justify-start pt-5">
              <a
                href="https://play.google.com/store/apps/details?id=my.snapnpay.tulus.android&hl=en&gl=US"
                target="_blank"
              >
                <img src={GooglePlayBadge} alt="" style={{ width: "190px" }} />
              </a>
              <a
                href="https://apps.apple.com/my/app/tulus-zakat-sedekah-online/id1529924310"
                target="_blank"
              >
                <img src={AppStoreBadge} alt="" style={{ width: "170px" }} />
              </a>
            </div>
          </div>
          <div className="flex items-center place-content-end">
            <img src={AppMockup} alt="" width={400} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
