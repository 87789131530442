import { add } from "date-fns";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "../../../components/Form";
import HeaderBanner from "../../../components/HeaderBanner";
import { states, icTypes, payerTypes, zakatTypes } from "../../../data/data";
import { currentDateTime, paymentAPI } from "../../../utils";
import Breadcrumbs from "../../../components/Breadcrumbs";

type FormData = {
  email: string;
  name: string;
  ic_type: string;
  ic_no: string;
  phone_no: string;
  address: string;
  address_2: string;
  address_3: string;
  postcode: string;
  state: string;
  payer_type: string;
  zakat_type: string;
  zakat_haul: string;
  amount: number;
  agency: string;
  returnUrl: string;
  refNo: string;
};

const generate_returnurl = (refNo: string): string => {
  let host: string = "http://localhost:12120";
  if (!/localhost/.test(window.location.host)) {
    host = window.location.protocol + "//" + window.location.host;
  }
  return refNo !== "" ? `${host}/api/return/fpx/${refNo}` : "";
};

const generate_extradata = (
  payer: FormData,
  extraData: object | null
): string => {
  let returnURL = payer.refNo;
  if (returnURL !== "" && extraData !== null) {
    returnURL = `${returnURL}~${JSON.stringify(extraData)}`;
  }
  return returnURL;
};

const ZakatForm = () => {
  const [payer, setPayer] = useState<FormData>({
    email: "",
    name: "",
    ic_type: "",
    ic_no: "",
    phone_no: "",
    address: "",
    address_2: "",
    address_3: "",
    postcode: "",
    state: "",
    payer_type: "",
    zakat_type: "",
    zakat_haul: "",
    amount: 10,
    agency: "ZKLZS",
    returnUrl: "",
    refNo: "",
  });

  const [error, setError] = useState<any>({
    email: "",
    name: "",
    ic_type: "",
    ic_no: "",
    phone_no: "",
    address: "",
    address_2: "",
    address_3: "",
    postcode: "",
    state: "",
    payer_type: "",
    zakat_type: "",
    zakat_haul: "",
    amount: "",
    refNo: "",
  });

  const [btnDisabled, setBtnDisabled] = useState(false);

  const navigate = useNavigate();

  // const [refNo, setRefNo] = useState<string>("");

  let years = [];
  for (let i = 0; i < 5; i++) {
    years.push(new Date().getFullYear() - i);
  }

  const emelRegex = RegExp(
    /^\s?[A-Z0–9]+[A-Z0–9._+-]{0,}@[A-Z0–9._+-]+\.[A-Z0–9]{2,4}\s?$/i
  );

  const numberRegex = RegExp(/^\d+$/i);

  const onChangePayer = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    let newRefNo;

    // setPayer({ ...payer, [name]: value });

    let newError: any = JSON.parse(JSON.stringify(error));

    if (name === "email") {
      if (!emelRegex.test(value)) {
        newError.email = "Emel tidak Sah";
        setBtnDisabled(false);
      } else if (value !== "" && value.trim().length === 0) {
        newError.email = "Diperlukan";
        setBtnDisabled(false);
      } else {
        newError.email = null;
        setBtnDisabled(true);
      }
    }

    if (name === "name") {
      if (value !== "" && value.trim().length === 0) {
        newError.name = "Diperlukan";
        setBtnDisabled(false);
      } else if (value !== "" && value.trim().length < 5) {
        newError.name = "Nama kurang dari 5 aksara";
        setBtnDisabled(false);
      } else {
        newError.name = null;
        setBtnDisabled(true);
      }
    }

    if (name === "ic_type") {
      if (value.trim().length === 0) {
        newError.ic_type = "Diperlukan";
        setBtnDisabled(false);
      } else {
        newError.ic_type = null;
        setBtnDisabled(true);
      }
    }

    if (name === "ic_no") {
      if (value !== "" && value.trim().length === 0) {
        newError.ic_no = "Required";
        setBtnDisabled(false);
      } else if (!numberRegex.test(value)) {
        newError.ic_no = "No kad pengenalan tidak sah";
        setBtnDisabled(false);
      } else if (value !== "" && value.trim().length <= 11) {
        newError.ic_no = "No kad pengenalan kurang dari 12 nombor aksara";
        setBtnDisabled(false);
      } else if (value !== "" && value.trim().length > 12) {
        newError.ic_no = "No kad pengenalan kurang lebih dari 12 nombor aksara";
        setBtnDisabled(false);
      } else {
        newError.ic_no = null;
        setBtnDisabled(true);
      }
    }

    if (name === "phone_no") {
      if (value !== "" && value.trim().length === 0) {
        newError.phone_no = "Diperlukan";
        setBtnDisabled(false);
      } else if (!numberRegex.test(value)) {
        newError.phone_no = "Nombor telefon tidak sah";
        setBtnDisabled(false);
      } else if (value !== "" && value.trim().length < 10) {
        newError.phone_no = "Nombor telefon kurang daripada 11 nombor aksara";
        setBtnDisabled(false);
      } else if (value !== "" && value.trim().length > 11) {
        newError.phone_no = "Nombor telefon lebih daripada 11 nombor aksara";
        setBtnDisabled(false);
      } else {
        newError.phone_no = null;
        setBtnDisabled(true);
      }
    }

    if (name === "address") {
      if (value !== "" && value.trim().length === 0) {
        newError.address = "Diperlukan";
        setBtnDisabled(false);
      } else if (value !== "" && value.trim().length >= 50) {
        newError.address = "Alamat lebih daripdada 50 aksara";
        setBtnDisabled(false);
      } else if (value !== "" && value.trim().length < 3) {
        newError.address = "Alamat kurang daripada 3 aksara";
      } else {
        newError.address = null;
        setBtnDisabled(true);
      }
    }

    if (name === "postcode") {
      if (value !== "" && value.trim().length === 0) {
        newError.postcode = "Diperlukan";
        setBtnDisabled(false);
      } else if (value !== "" && !numberRegex.test(value)) {
        newError.postcode = "Poskod tidak sah";
        setBtnDisabled(false);
      } else if (value !== "" && value.trim().length !== 5) {
        newError.postcode = "Poskod kurang atau lebih daripada 5 nombor aksara";
        setBtnDisabled(false);
      } else {
        newError.postcode = null;
        setBtnDisabled(true);
      }
    }

    if (name === "state") {
      if (value.trim().length === 0) {
        newError.state = "Diperlukan";
        setBtnDisabled(false);
      } else {
        newError.state = null;
        setBtnDisabled(true);
      }
    }

    if (name === "payer_type") {
      if (value.trim().length === 0) {
        newError.payer_type = "Diperlukan";
        setBtnDisabled(false);
      } else {
        newError.payer_type = null;
        setBtnDisabled(true);
      }
    }

    if (name === "zakat_type") {
      if (value.trim().length === 0) {
        newError.zakat_type = "Diperlukan";
        setBtnDisabled(false);
      } else {
        newError.zakat_type = null;
        setBtnDisabled(true);
      }
    }

    if (name === "zakat_haul") {
      if (value.trim().length === 0) {
        newError.zakat_haul = "Diperlukan";
        setBtnDisabled(false);
      } else {
        newError.zakat_haul = null;
        setBtnDisabled(true);
      }
    }

    if (name == "amount") {
      if (value !== "" && value < 10) {
        newError.amount = "Minimum amaun RM 10";
        setBtnDisabled(false);
      } else if (!numberRegex.test(value)) {
        newError.amount = "Amaun tidak sah";
        setBtnDisabled(false);
      } else {
        newError.amount = null;
        setBtnDisabled(true);
      }
    }

    const newPayer = { ...payer, [name]: value, refNo: currentDateTime() };

    setPayer({ ...newPayer });

    // newRefNo =
    //   currentDateTime() +
    //   "~" +
    //   JSON.stringify({
    //     zakat_ic_no: newPayer.ic_no,
    //     zakat_ic_type: newPayer.ic_type,
    //     zakat_type: newPayer.zakat_type,
    //     zakat_name: newPayer.name.toUpperCase(),
    //     zakat_phone_no: newPayer.phone_no,
    //     zakat_address: newPayer.address,
    //     zakat_address_2: newPayer.address_2,
    //     zakat_address_3: newPayer.address_3,
    //     zakat_postcode: newPayer.postcode,
    //     zakat_state: newPayer.state.toUpperCase(),
    //     zakat_haul: newPayer.zakat_haul,
    //     zakat_payer_type: newPayer.payer_type,
    //   });
    // setRefNo(newRefNo);
    setError(newError);
  };

  let extraData = {
    zakat_ic_no: payer.ic_no,
    zakat_ic_type: payer.ic_type,
    zakat_type: payer.zakat_type,
    zakat_name: payer.name.toUpperCase(),
    zakat_phone_no: payer.phone_no,
    zakat_address: payer.address,
    zakat_address_2: payer.address_2,
    zakat_address_3: payer.address_3,
    zakat_postcode: payer.postcode,
    zakat_state: payer.state.toUpperCase(),
    zakat_haul: payer.zakat_haul,
    zakat_payer_type: payer.payer_type,
  };

  // console.log(paymentAPI());

  return (
    <Fragment>
      <HeaderBanner header={"Zakat Selangor"} />
      <Breadcrumbs
        breadCrumbItems={[
          {
            url: "/",
            name: "Utama",
          },
          {
            url: "/ZakatForm",
            name: "Bayar Zakat",
          },
        ]}
      />

      <div className="flex justify-center my-10 px-5">
        <div className="border border-grey-200 rounded-md shadow-sm w-full md:w-5/6 lg:w-9/12">
          <form
            name={"zakat-form"}
            className="m-5"
            action="https://pay.tulus.my"
            method="POST"
          >
            <div className="text-2xl text-cyan-500 font-semibold py-2">
              Maklumat Pembayar <br />
              <span className="text-sm text-cyan-900">
                *Sila isi semua maklumat yang dikehendaki untuk menunaikan zakat
                anda
              </span>
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label
                htmlFor="email"
                className="text-sm font-bold text-gray-800"
              >
                Email <span className="text-red-600">*</span>
              </label>

              <div className="w-full md:w-9/12">
                <input
                  type="text"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full xl:w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
                  placeholder="example@example.com"
                  name="email"
                  value={payer.email}
                  onChange={onChangePayer}
                  required
                />
                <span className="text-red-600">{error.email}</span>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label htmlFor="name" className="text-sm font-bold text-gray-800">
                Name <span className="text-red-600">*</span>
              </label>
              <div className="w-full md:w-9/12">
                <input
                  type="text"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
                  placeholder="Name"
                  name="name"
                  value={payer.name}
                  onChange={onChangePayer}
                  required
                />
                <span className="text-red-600">{error.name}</span>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label
                htmlFor="ic_type"
                className="text-sm font-bold text-gray-800"
              >
                Jenis Kad Pengenalan <span className="text-red-600">*</span>
              </label>
              <div className="w-full md:w-9/12">
                <select
                  id="ic_type"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
                  name="ic_type"
                  value={payer.ic_type}
                  onChange={onChangePayer}
                  required
                >
                  <option value="">Sila Pilih</option>
                  {icTypes.ic_type &&
                    icTypes.ic_type.map((ic, i) => (
                      <option key={i} value={ic.name}>
                        {ic.description}
                      </option>
                    ))}
                </select>
                <span className="text-red-600">{error.ic_type}</span>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label
                htmlFor="ic_no"
                className="text-sm font-bold text-gray-800"
              >
                No. Kad Pengenalan <span className="text-red-600">*</span>
              </label>
              <div className="w-full md:w-9/12">
                <input
                  type="text"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
                  name="ic_no"
                  placeholder="No. Kad Pengenalan"
                  value={payer.ic_no}
                  onChange={onChangePayer}
                  required
                />
                <span className="text-red-600">{error.ic_no}</span>
              </div>
            </div>

            <div className="text-2xl text-cyan-500 font-semibold py-2">
              Alamat Pembayar
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label
                htmlFor="phone_no"
                className="text-sm font-bold text-gray-800"
              >
                No. Telefon <span className="text-red-600">*</span>
              </label>
              <div className="w-full md:w-9/12">
                <input
                  type="text"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
                  name="phone_no"
                  placeholder="No. Telefon"
                  value={payer.phone_no}
                  onChange={onChangePayer}
                  required
                />
                <span className="text-red-600">{error.phone_no}</span>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label
                htmlFor="address"
                className="text-sm font-bold text-gray-800"
              >
                Alamat <span className="text-red-600">*</span>
              </label>
              <div className="w-full md:w-9/12">
                <input
                  type="text"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
                  name="address"
                  placeholder="Alamat"
                  value={payer.address}
                  onChange={onChangePayer}
                  required
                />
                <span className="text-red-600">{error.address}</span>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label
                htmlFor="address_2"
                className="text-sm font-bold text-gray-800"
              >
                Alamat 2
              </label>
              <div className="w-full md:w-9/12">
                <input
                  type="text"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
                  name="address_2"
                  placeholder="Alamat 2"
                  value={payer.address_2}
                  onChange={onChangePayer}
                  required
                />
                <span className="text-red-600">{error.address_2}</span>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label
                htmlFor="address_3"
                className="text-sm font-bold text-gray-800"
              >
                Alamat 3
              </label>
              <div className="w-full md:w-9/12">
                <input
                  type="text"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
                  name="address_3"
                  placeholder="Alamat 3"
                  value={payer.address_3}
                  onChange={onChangePayer}
                  required
                />
                <span className="text-red-600">{error.address_3}</span>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label
                htmlFor="postcode"
                className="text-sm font-bold text-gray-800"
              >
                Poskod <span className="text-red-600">*</span>
              </label>
              <div className="w-full md:w-9/12">
                <input
                  type="text"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
                  name="postcode"
                  placeholder="Poskod"
                  value={payer.postcode}
                  onChange={onChangePayer}
                  required
                />
                <span className="text-red-600">{error.postcode}</span>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label
                htmlFor="state"
                className="text-sm font-bold text-gray-800"
              >
                Negeri <span className="text-red-600">*</span>
              </label>
              <div className="w-full md:w-9/12">
                <select
                  id="stateID"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
                  name="state"
                  value={payer.state}
                  onChange={onChangePayer}
                  required
                >
                  <option value="">Sila Pilih</option>
                  {states.negeri &&
                    states.negeri.map((state, i) => (
                      <option key={i} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                </select>
                <span className="text-red-600">{error.state}</span>
              </div>
            </div>

            <div className="text-2xl text-cyan-500 font-semibold py-2">
              Jenis Zakat Hendak Dibayar
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3 capitalize">
              <label
                htmlFor="payer_type"
                className="text-sm font-bold text-gray-800"
              >
                Jenis Pembayar <span className="text-red-600">*</span>
              </label>
              <div className="w-full md:w-9/12">
                <select
                  id="payer_type_ID"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800 capitalize"
                  name="payer_type"
                  value={payer.payer_type}
                  onChange={onChangePayer}
                  required
                >
                  <option value="">Sila Pilih</option>
                  {payerTypes.payer_type &&
                    payerTypes.payer_type.map((ptype, i) => (
                      <option key={i} value={ptype.name}>
                        {ptype.name}
                      </option>
                    ))}
                </select>
                <span className="text-red-600">{error.payer_type}</span>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3 capitalize">
              <label
                htmlFor="zakat_type"
                className="text-sm font-bold text-gray-800"
              >
                Jenis Zakat <span className="text-red-600">*</span>
              </label>
              <div className="w-full md:w-9/12">
                <select
                  id="zakat_type_ID"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800 capitalize"
                  name="zakat_type"
                  value={payer.zakat_type}
                  onChange={onChangePayer}
                  required
                >
                  <option value="">Sila Pilih</option>
                  {zakatTypes.zakat_types &&
                    zakatTypes.zakat_types.map((zakat_type, i) => (
                      <option key={i} value={zakat_type.name}>
                        {zakat_type.description}
                      </option>
                    ))}
                </select>
                <span className="text-red-600">{error.zakat_type}</span>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3 capitalize">
              <label
                htmlFor="zakat_haul"
                className="text-sm font-bold text-gray-800"
              >
                Tahun Haul <span className="text-red-600">*</span>
              </label>
              <div className="w-full md:w-9/12">
                <select
                  id="zakat_haul_id"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800 capitalize"
                  name="zakat_haul"
                  value={payer.zakat_haul}
                  onChange={onChangePayer}
                >
                  <option value="">Sila Pilih</option>
                  {years.map((y, i) => (
                    <option key={i} value={y.toString()}>
                      {y.toString()}
                    </option>
                  ))}
                </select>
                <span className="text-red-600">{error.zakat_haul}</span>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label
                htmlFor="amount"
                className="text-sm font-bold text-gray-800"
              >
                Amaun <span className="text-red-600">*</span>
              </label>
              <div className="w-full md:w-9/12">
                <input
                  type="text"
                  className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
                  placeholder="Amaun"
                  name="amount"
                  value={payer.amount}
                  onChange={onChangePayer}
                  required
                />
                <span className="text-red-600">{error.amount}</span>
              </div>
            </div>
            <input
              type="hidden"
              className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full lg:w-9/12 shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
              placeholder=""
              name="refNo"
              value={generate_extradata(payer, extraData)}
              onChange={onChangePayer}
            />
            <input
              type="hidden"
              className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full lg:w-9/12 shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
              placeholder=""
              name="returnUrl"
              value={generate_returnurl(payer.refNo)}
              onChange={onChangePayer}
            />
            <input
              type="hidden"
              className="bg-gray-50 border border-grey-500 rounded pl-3 py-2 w-full lg:w-9/12 shadown-sm focus:outline-none focus:border-cyan-300 bg-transparent placeholder-gray-400 text-gray-800"
              placeholder=""
              name="agency"
              value={payer.agency}
              onChange={onChangePayer}
            />

            <div className="flex flex-col md:flex-row justify-evenly py-5 gap-4">
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="border border-grey-600 w-full md:w-3/6 lg:w-1/3 rounded-md p-2 self-center text-center bg-white hover:bg-gray-100 hover:scale-105 transition duration-300 ease-out hover:ease-in"
              >
                Kembali
              </button>
              <button
                disabled={!btnDisabled}
                type="submit"
                className="border border-cyan-700 w-full md:w-3/6 lg:w-1/3 rounded-md p-2 self-center text-center bg-cyan-500 hover:bg-cyan-600 text-white hover:scale-105 transition duration-300 ease-out hover:ease-in disabled:opacity-75"
              >
                Bayar
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default ZakatForm;
