import React from "react";
import Banner from "../assets/images/banner.jpg";

const Hero = () => {
  return (
    <div className="flex flex-col justify-center items-center border-gray-200 shadow-md">
      <img src={Banner} alt="" />
    </div>
  );
};

export default Hero;
