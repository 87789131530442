import React, { Fragment } from "react";
import Banner from "../../../components/Banner";
import LZSBanner from "../../../assets/images/banner/banner_lzs.jpg";
import LZSLogo from "../../../assets/images/lzs_logo.png";
import LZSbayar from "../../../assets/images/lzs_bayar.png";
import LZSKalkulator from "../../../assets/images/lzs_kalkulator.png";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";

const zakatObjects = [
  {
    title: "Objektif LZS",
    content: [
      {
        detail: "1. Memperkasa Pengurusan Zakat",
      },
      {
        detail: "2. Memaksimakan Kutipan Zakat",
      },
      {
        detail: "3. Meningkatkan Keberkesanan Agihan Zakat",
      },
    ],
  },
  {
    title: "Polisi Kualiti",
    content: [
      {
        detail:
          "- Lembaga Zakat Selangor (LZS) bertekad memberi perkhidmatan berkualiti yang menyeluruh dan berterusan bagi urusan kutipan dan agihan zakat berlandaskan hukum syarak bagi memenuhi kehendak dan kepuasan pelanggan.",
      },
    ],
  },
  {
    title: "Jenis Pilihan",
    subtitle:
      "Jom tunaikan zakat dengan Infaq Selangor! Klik Link Di Bawah Sekarang!",
    images: [
      {
        img: LZSKalkulator,
        path: "",
        name: "Kalkulator Zakat",
      },
      {
        img: LZSbayar,
        path: "/zakat/form",
        name: "LZS Logo",
      },
    ],
  },
];

const ZakatMain = () => {
  return (
    <>
      <Banner
        icon={LZSLogo}
        title={"Lembaga Zakat Selangor (LZS)"}
        content={
          "Memikul tanggungjawab yang lebih besar disamping meningkatkan imej dalam menguruskan kutipan dan agihan zakat yang telah mencapai lebih RM100 juta setahun."
        }
        image={LZSBanner}
        zakat="Bayar Zakat"
      />
      <div>
        <Breadcrumbs
          breadCrumbItems={[
            {
              url: "/",
              name: "Utama",
            },
            {
              url: "/ZakatMain",

              name: "Lembaga Zakat Selangor",
            },
          ]}
        />
      </div>

      <div className="mx-auto px-5 md:px-12 lg:px-16 pb-20">
        {zakatObjects.map((zakat, i) => (
          <div className="my-10 font-bold" key={i}>
            <div className="box-border w-full md:w-5/6">
              <h1 className="text-lg md:text-3xl text-cyan-500 pb-2 md:pb-5">
                {zakat.title}
              </h1>
              <div className="text-base font-normal">{zakat.subtitle}</div>
              {zakat.content &&
                zakat.content.map((cont, j) => (
                  <ul className="font-normal leading-loose" key={j}>
                    <div className="text-justify">
                      <div className="">{cont.detail}</div>
                    </div>
                  </ul>
                ))}
            </div>

            <div className="grid grid-cols md:grid-cols-4 gap-2 pt-5">
              {zakat.images &&
                zakat.images.map((image, k) => (
                  <div key={k}>
                    <Link to={image.path}>
                      <img
                        src={image.img}
                        alt={image.name}
                        className="w-80 hover:scale-105 ease-in-out duration-300"
                      />
                    </Link>
                  </div>
                ))}
            </div>

            {/* <div className="bg-white px-6 p-4 mt-4 rounded-2xl border-2 border-yellow-500 inline-block">
              <p className="text-sm text-yellow-600">
                Bayar Zakat
              </p>
          </div> */}
          </div>
        ))}
      </div>
    </>
  );
};

export default ZakatMain;
