export type FormNegeriCollection = {
  id: number;
  name: string;
};

export type FormCollection = {
  negeri: FormNegeriCollection[];
};

export const icTypes = {
  ic_type: [
    { id: 1, name: "ic_no", description: "Awam" },
    { id: 2, name: "ic_tentera", description: "Tentera" },
    { id: 3, name: "ic_polis", description: "Polis" },
    { id: 4, name: "ic_passport", description: "Passport" },
  ],
};

export const states = {
  negeri: [
    { id: 1, name: "Johor" },
    { id: 2, name: "Kedah" },
    { id: 3, name: "Kelantan" },
    { id: 4, name: "Melaka" },
    { id: 5, name: "Negeri Sembilan" },
    { id: 6, name: "Pahang" },
    { id: 7, name: "Pulau Pinang" },
    { id: 8, name: "Perak" },
    { id: 9, name: "Perlis" },
    { id: 10, name: "Sabah" },
    { id: 11, name: "Sarawak" },
    { id: 12, name: "Selangor" },
    { id: 13, name: "Terengganu" },
    { id: 14, name: "Wilayah Persekutuan" },
    { id: 15, name: "Wilayah Persekutuan Labuan" },
    { id: 16, name: "Wilayah Persekutuan Putrajaya" },
  ],
};

export const payerTypes = {
  payer_type: [
    { id: 1, name: "individu" },
    { id: 2, name: "organisasi" },
  ],
};

export const zakatTypes = {
  zakat_types: [
    { id: 1, name: "zakat_pendapatan", description: "Zakat Pendapatan" },
    { id: 2, name: "zakat_perniagaan", description: "Zakat Perniagaan" },
    { id: 3, name: "zakat_simpanan", description: "Zakat Simpanan" },
    { id: 4, name: "zakat_saham", description: "Zakat Saham" },
    { id: 5, name: "zakat_kwsp", description: "Zakat KWSP" },
    { id: 6, name: "zakat_emas", description: "Zakat Emas" },
    { id: 7, name: "zakat_perak", description: "Zakat Perak" },
    { id: 8, name: "qadha_zakat", description: "Qadha Zakat" },
    { id: 9, name: "zakat_harta", description: "Zakat Harta" },
    { id: 10, name: "zakat_tanaman", description: "Zakat tanaman" },
    { id: 11, name: "zakat_padi", description: "Zakat Padi" },
    { id: 12, name: "zakat_ternakan", description: "Zakat Ternakan" },
  ],
};
