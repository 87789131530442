import React, { Fragment, useState } from "react";
import HeaderBanner from "../../../components/HeaderBanner";
import { mosqueList } from "../../../data/masjidList";
import MosqueDefault from "../../../assets/images/default_masjid.jpg";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";

const ITEMS_PER_PAGE = 8; // Number of items per page

const SedekahMain = () => {
  const [searchMosque, setSearchMosque] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const filteredMosqueList = mosqueList.filter((val) => {
    if (searchMosque === "") {
      return true;
    } else if (val.nama.toLowerCase().includes(searchMosque.toLowerCase())) {
      return true;
    }
    return false;
  });

  const totalPages = Math.ceil(filteredMosqueList.length / ITEMS_PER_PAGE);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = filteredMosqueList.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Fragment>
      <HeaderBanner header={"Sedekah"} />
      <div>
        <Breadcrumbs
          breadCrumbItems={[
            {
              url: "/",
              name: "Utama",
            },
            {
              url: "/Sedekah",
              name: "Sedekah",
            },
          ]}
        />
      </div>

      <div className="container mx-auto px-3 py-10">
        <div className="text-cyan-500 text-2xl font-semibold">
          Carian Masjid
        </div>
        <div className="flex justify-start my-5">
          <div className="w-full md:w-full lg:w-5/6">
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center py-3">
              <label
                htmlFor="masjid"
                className="text-sm font-bold text-gray-800"
              >
                Nama Masjid / Surau
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-grey-700 rounded pl-3 py-2 w-full lg:w-9/12 dark:border-gray-300 shadown-sm focus:outline-none focus:border-indigo-700 bg-transparent placeholder-gray-400 text-gray-800"
                placeholder="Nama Masjid"
                onChange={(e) => setSearchMosque(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="py-4">
          <div className="text-cyan-500 text-2xl font-semibold">
            Senarai Pilihan Bersedekah
          </div>
          <div className="text-base pb-5">
            Jom bersedekah bersama Infaq Selangor!
          </div>
        </div>

        <div className="mx-auto grid grid-cols md:grid-cols-2 lg:grid-cols-4 gap-4 pb-10">
          {currentItems.map((mosque, id) => {
            return (
              <div
                key={id}
                className="bg-white rounded-lg border-gray-200 shadow-md md:m-0 overflow-hidden flex"
              >
                <Link
                  to="/sedekah/info"
                  state={{
                    agency_code: mosque.agency_code,
                    name: mosque.nama,
                    gambar: mosque.gambar,
                    sejarah: mosque.sejarah,
                    kemudahan: mosque.kemudahan,
                    alamat: mosque.alamat,
                    daerah: mosque.daerah,
                    no_tel: mosque.no_tel,
                    lat: mosque.lat,
                    long: mosque.long,
                  }}
                  className="flex flex-col justify-center"
                >
                  <img
                    src={mosque.gambar && mosque.gambar}
                    alt={mosque.nama}
                    className="rounded-t-lg w-screen h-1/2"
                    onError={(e) => {
                      e.currentTarget.src = MosqueDefault;
                    }}
                  />
                  <div className="p-5 flex flex-col flex-1">
                    <div className="py-3">{mosque.nama}</div>
                    <div className="font-normal text-grey-600 text-sm py-5 flex-1">
                      {mosque.alamat}
                    </div>
                    <div className="flex items-center justify-between text-center text-white bg-cyan-500 px-5 py-3 rounded-md shadow-md hover:scale-105 ease-in-out duration-300">
                      <span className="">Lebih Lanjut</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>

        {/* Pagination */}
        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(
            (pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                className={`mx-2 py-2 px-4 rounded-md ${
                  pageNumber === currentPage
                    ? "bg-cyan-500 text-white"
                    : "bg-gray-200 text-gray-600 hover:bg-gray-300"
                }`}
              >
                {pageNumber}
              </button>
            )
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default SedekahMain;
