import React, { Fragment, useState } from "react";
import Banner from "../../../components/Banner";
import YideLogo from "../../../assets/images/yide_logo.png";
import YideBanner from "../../../assets/images/banner/banner_yide.jpg";
import { Link } from "react-router-dom";
import AlmsIcon from "../../../assets/images/icon/alms.svg";
import BuildingIcon from "../../../assets/images/icon/building.svg";
import ChildIcon from "../../../assets/images/icon/child.svg";
import DoctorIcon from "../../../assets/images/icon/doctor.svg";
import EquipmentIcon from "../../../assets/images/icon/equipment.svg";
import GroceryIcon from "../../../assets/images/icon/grocery.svg";
import HomeIcon from "../../../assets/images/icon/home.svg";
import Medicine from "../../../assets/images/icon/medicine.svg";
import Welfare from "../../../assets/images/icon/welfare.svg";
import Breadcrumbs from "../../../components/Breadcrumbs";

const yideObjects = [
  {
    title: "Hal Ehwal Berkaitan Anak-anak Yatim Negeri Selangor",
    content: [
      {
        detail:
          "Melaksanakan program agihan sumbangan kewangan hari raya Aidilfitri kepada anak-anak yatim di Negeri Selangor dan semua hal ehwal yang berkaitan anak-anak yatim seperti bantuan dana pendidikan,perubatan,penyerahan bantuan pakaian sekolah serta mewujudkan program Celik Al-Quran dalam usaha melahirklan generasi pintar Al-Quran di kalangan anak-anak Yatim",
      },
    ],
  },
  {
    title: "Musibah/Malapetaka",
    content: [
      {
        detail:
          "Memberi Sumbangan dan barangan secara ‘Ad-Hoc’ kepada golongan sasaran dalam bentuk kewangan tunai serta barangan keperluan harian seperti makanan & minuman dan barang kelengkapan diri kepada mangsa-mangsa yang ditimpa musibah dan malapetaka seperti tanah runtuh, banjir serta kemalangan.",
      },
    ],
  },
  {
    title: "Perkhidmatan Rawatan Hemodialisis, Pusat Dialisis MAIS",
    content: [
      {
        detail:
          "- Menyediakan perkhidmatan rawatan hemodialisis di Enam (6) buah cawangan Pusat Dialisis MAIS (PDM) di Shah Alam,Kapar, Kuala Selangor,Sunagi Besar, Taman Melawati (Gombak) dan Bukit Jelutong (Shah Alam)",
      },
      {
        detail:
          "- Menguruskan dan mengawal selia serta klinikal semua Pusat Dialisis MAIS.",
      },
      {
        detail:
          "- Memberikan perkhidmatan rawatan hemodialisis kepada pesakit yang mengalami kegagalan buah pinggang dengan sempurna dan selamat serta memastikan pesakit berpuashati dengan layanan diberikan oleh Yayasan.",
      },
      {
        detail:
          "- Menerapkan pengisian rohani kepada pesakit-pesakit dengan memberikan ceramah dan tazkirah oleh Penceramah jemputan agar dapat memotivasikan diri untuk meneruskan kehidupan.",
      },
      {
        detail:
          "- Merancang dan melaksanakan program penerangan dan kesedaran berkaitan penjagaan kesihatan, pemakanan dan sebagainya kepada masyarakat umum seperti menganjurkan Hari Buah Pinggang Sedunia, Kempen Derma Darah dan Ceramah-Ceramah yang berkaitan bidang",
      },
      {
        detail:
          "- Merancang dan melaksanakan perkhidmatan yang berkaitan hemodialisis dan lain-lain program berkaitan.",
      },
    ],
  },
  {
    title: "Jenis Pilihan Infaq",
    subtitle:
      "Berinfaqlah dengan mudah bersama Infaq Selangor! Klik Link DiBawah Sekarang!",
    types: [
      {
        image: require("../../../assets/yide/anakyatim.jpg"),
        header: "Infaq Dana Anak Yatim",
        body: "Kongsikan kegembiraan kita bersama anak yatim dengan berinfak",
        content:
          "YIDE telah dipertanggungjawabkan oleh Majlis Agama Islam Selangor (MAIS) sebagai Penyelaras Kluster Kebajikan Anak-Anak Yatim di Negeri Selangor di bawah Jawatankuasa Pembangunan NGO-I MAIS untuk menyantuni dan mengawal selia sebanyak 19 buah rumah kebajikan anak yatim yang berdaftar dibawah YIDE dan YIDE juga telah menyantuni dengan membiayai keperluan anak-anak yatim di 9 daerah Negeri Selangor seperti pemberian barang keperluan asas persekolahan (Baju, Beg, Kasut dan Alat Tulis) dan pembangunan modal insan anak-anak yatim (Kursus Jati Diri, Kursus Motivasi Dan Kursus Intensif Peperiksaan).",
        agency: "IS-00341",
        payment_type: "dana_anak_yatim",
        objectives: [
          {
            id: 1,
            image: AlmsIcon,
            description:
              "Mendapatkan dana bagi membiayai keperluan asas anak-anak yatim dan asnaf.",
          },
          {
            id: 2,
            image: ChildIcon,
            description:
              "Mengutip dana bagi melaksanakan program sosial dan kebajikan khusus kepada anak-anak ini.",
          },
          {
            id: 3,
            image: BuildingIcon,
            description:
              "Memerlukan dana untuk menjaga pengurusan rumah -rumah anak yatim yang berdaftar di bawah YIDE.",
          },
        ],
        // {"name":"Abdul Kadir Mohd Aris","email":"kadir@tuxuri.com","phone_no":"0177988934","type":"dana_anak_yatim"}
      },
      {
        image: require("../../../assets/yide/pembinaan.jpg"),
        header: "Infaq Dana Pembinaan Kompleks Anak Yatim",
        body: "Salurkan dana dan sumbangan kepada pembinaan kompleks anak yatim",
        content:
          "YIDE mengorak langkah sendiri menguruskan kompleks ini dengan menempatkan 200 anak-anak yatim/fakir miskin lelaki dalam satu-satu masa. Pada 19 Ogos 2019 yang lalu DYTM Raja Muda Selangor telah berkenan menyempurnakan cadangan Pembangunan Kompleks Anak Yatim Pulau Indah dan melancarkan tabung wakaf khas bagi tujuan merealisasikan pembangunan kompleks ini agar dapat disiapkan dalam tempoh yang ditetapkan. Konsep yang dibangunkan ini bercirikan Islamik Kotemporari dan ianya akan menjadi sebuah kompleks anak yatim termoden di Negeri Selangor berjumlah RM 10 Juta kos pembangunannya.",
        agency: "IS-00341",
        payment_type: "pembinaan_kompleks_anak_yatim",
        objectives: [
          {
            id: 1,
            image: BuildingIcon,
            description:
              "Meringankan beban kos operasi, pengurusan dan pentadbiran pembinaan kompleks.",
          },
          {
            id: 2,
            image: HomeIcon,
            description:
              "Membina bangunan kompleks yang mempunyai kemudahan fasiliti dan prasarana lengkap.",
          },
          {
            id: 3,
            image: AlmsIcon,
            description:
              "Mengumpul tabung dana bagi menampung kos penyelenggaraan bulanan kompleks ini.",
          },
        ],
        // {"name":"Abdul Kadir Mohd Aris","email":"kadir@tuxuri.com","phone_no":"0177988934","type":"pembinaan_kompleks_anak_yatim"}
      },
      {
        image: require("../../../assets/yide/dialisis.jpg"),
        header: "Infaq Dana Dialisis",
        body: "Hulurkan sumbangan dan sedikit bantuan dana untuk pesakit dialisis.",
        content:
          "YIDE mempunyai 6 cawangan Pusat Dialisis MAIS yang meliputi beberapa daerah di Negeri Selangor seperti Pusat Dialisis MAIS Cawangan Shah Alam, Cawangan Bukit Jelutong, Cawangan Kapar, Cawangan Sungai Besar, Cawangan Kuala Selangor dan Cawangan Taman Melawati. Sebanyak RM 500,000.00 diperlukan setiap tahun bagi membiayai kos kos rawatan bagi pesakit dialisis yang kurang berkemampuan dan membiayai ujian darah serta ubat ubatan yang diperlukan oleh setiap pesakit.",
        agency: "IS-00341",
        payment_type: "dana_dialisis",
        objectives: [
          {
            id: 1,
            image: EquipmentIcon,
            description:
              "Mengantikan mesin hemodialisis dan peralatan klinikal yang telah rosak dengan yang baru.",
          },
          {
            id: 2,
            image: DoctorIcon,
            description:
              "Membiayai kos-kos rawatan bagi pesakit dialisis yang kurang berkemampuan.",
          },
          {
            id: 3,
            image: Medicine,
            description:
              "Bantuan perubatan am seperti ubat ubatan dan ujian darah yang diperlukan pesakit.",
          },
        ],
        // {"name":"Abdul Kadir Mohd Aris","email":"kadir@tuxuri.com","phone_no":"0197863451","type":"dana_dialisis"}
      },
      {
        image: require("../../../assets/yide/musibah.jpg"),
        header: "Infaq Dana Musibah",
        body: "Bantu saudara kita yang ditimpa musibah dengan menyediakan keperluan asas.",
        content:
          "YIDE sangat komited dan berusaha sedaya upaya membantu dalam beri sumbangan dan barangan keperluan harian seperti makanan & minuman dan barang kelengkapan diri kepada mangsa-mangsa yang ditimpa musibah dan malapetaka seperti tanah runtuh, banjir,kebakaran serta kemalangan. Setiap dana yang telah dikumpul akan diberikan secara wang tunai kepada mangsa musibah sebanyak RM 500.00 hingga RM 1,500.00 mengikut keperluan semasa musibah yang melanda bagi meringankan beban mangsa.",
        agency: "IS-00341",
        payment_type: "dana_musibah",
        objectives: [
          {
            id: 1,
            image: AlmsIcon,
            description:
              "Meringankan beban mangsa yang ditimpa musibah secara langsung.",
          },
          {
            id: 2,
            image: GroceryIcon,
            description:
              "Membantu menyediakan barang keperluan asas mangsa semasa musibah melanda.",
          },
          {
            id: 3,
            image: Welfare,
            description:
              "Penyewaan barangan pembersihan rumah untuk pembersihan rumah yang dilanda musibah.",
          },
        ],
        // {"name":"Abdul Kadir Mohd Aris","email":"kadir@tuxuri.com","phone_no":"0177988934","type":"dana_musibah"}
      },
    ],
  },
];

const InfaqMain = () => {
  // const [selectedType, setSelectedType] = useState(null);

  // const handleTypeClick = (type) => {
  //   setSelectedType(type);
  // // };
  return (
    <Fragment>
      <Banner
        icon={YideLogo}
        title={"Yayasan Islam Darul Ehsan (YIDE) "}
        content={
          "Perbadanan Infaq Selangor berharap agar dapat melaksanakan kebajikan kepada masyarakat di negeri Selangor khususnya dan Malaysia amnya dengan kerjasama dan sokongan padu daripada pihak-pihak yang prihatin bagi memastikan objektif-objektif penubuhannya tercapai."
        }
        image={YideBanner}
        scroll="Scroll down"
      />
      <div>
        <Breadcrumbs
          breadCrumbItems={[
            {
              url: "/",
              name: "Utama",
            },
            {
              url: "/Infaq",
              name: "Yayasan Islam Darul Ehsan (YIDE)",
            },
          ]}
        />
      </div>

      <div className="mx-auto px-5 md:px-12 lg:px-16 pb-20">
        {yideObjects.map((yide, i) => (
          <div className="my-10 font-bold" key={i}>
            <div
              // id="jenis-pilihan-infaq"
              id={"content_" + i}
              className="box-border w-full md:w-5/6"
            >
              <h1 className="text-lg md:text-2xl text-cyan-500 pb-2 md:pb-5">
                {yide.title}
              </h1>
              <div className="text-base font-normal">{yide.subtitle}</div>
              {yide.content &&
                yide.content.map((cont, j) => (
                  <ul
                    className="font-normal leading-loose text-justify"
                    key={j}
                  >
                    <div className="text-md">
                      <div className="">{cont.detail}</div>
                    </div>
                  </ul>
                ))}
            </div>

            <div className="mx-auto grid grid-cols md:grid-cols-2 lg:grid-cols-4 gap-4">
              {yide.types &&
                yide.types.map((type, k) => (
                  <div
                    key={k}
                    className="max-w-sm bg-white rounded-lg-border border-gray-200 shadow-md mt-5 flex"
                  >
                    <Link
                      to="/infaq/info"
                      state={{
                        type,
                      }}
                      className="flex flex-col"
                    >
                      <img className="rounded-t-lg" src={type.image} alt="" />
                      <div className="p-5 flex flex-col flex-1">
                        <div className="mb-2 text-lg font-bold tracking-tight text-gray-900">
                          {type.header}
                        </div>
                        <div className="mb-3 text-sm font-normal text-gray-500 flex-1">
                          {type.body}
                        </div>
                        <div className="flex items-center justify-between text-center text-white bg-cyan-500 px-5 py-3 rounded-md shadow-md hover:scale-105 ease-in-out duration-300">
                          <span className="">Lebih Lanjut</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default InfaqMain;
