import { createPopper } from "@popperjs/core";
import React, { createRef, Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/infaqselangorlogo_2.png";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const location = useLocation();
  const { pathname } = location;
  const splitPathname = pathname.split("/");

  return (
    <Fragment>
      <nav className="relative flex flex-wrap items-center justify-between px-2 pt-3 pb-2 bg-white border-gray-200 rounded shadow-sm">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between gap-5 lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className={`text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-black`}
            >
              <img src={Logo} alt="Selangor" className="w-36" />
            </Link>
            <button
              className="text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setOpenMenu(!openMenu)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (openMenu ? " flex" : " hidden")
            }
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto text-black gap-5 items-center">
              <li className="nav-item">
                <Link
                  to="/"
                  className={`px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug hover:opacity-75 hover:text-cyan-500 ${
                    splitPathname[1] === "" ? "text-cyan-500" : ""
                  }`}
                >
                  Utama
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/tentang-kami"
                  className={`px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug hover:opacity-75 hover:text-cyan-500 ${
                    splitPathname[1] === "tentang-kami" ? "text-cyan-500" : ""
                  }`}
                >
                  Tentang Kami
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/dasar-privasi"
                  className={`px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug hover:opacity-75 hover:text-cyan-500 ${
                    splitPathname[1] === "dasar-privasi" ? "text-cyan-500" : ""
                  }`}
                >
                  Dasar Privasi
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/soalan-lazim"
                  className={`px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug hover:opacity-75 hover:text-cyan-500 ${
                    splitPathname[1] === "soalan-lazim" ? "text-cyan-500" : ""
                  }`}
                >
                  Soalan Lazim
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/hubungi-kami"
                  className="px-3 py-2 flex items-center text-white hover:text-white text-sm uppercase font-bold leading-snug hover:opacity-75 bg-cyan-500 rounded-md border-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                  </svg>
                  Hubungi Kami
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default Navbar;
