import React, { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HeaderBanner from "../../../components/HeaderBanner";
import TulusLogo from "../../../assets/images/tulus_logo.png";
import MosqueDefault from "../../../assets/images/default_masjid.jpg";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { mosqueList } from "../../../data/masjidList";

const SedekahInfo = () => {
  const location = useLocation();
  const [info, setInfo] = React.useState<any>();
  // const state = location.state;
  // console.log(location.state);
  React.useEffect(() => {
    if (location && typeof info === "undefined") {
      setInfo(location?.state);
    }
  }, [location]);
  // console.log(info);
  return (
    <Fragment>
      <HeaderBanner header="Sedekah" />
      <Breadcrumbs
        breadCrumbItems={[
          {
            url: "/",
            name: "Utama",
          },
          {
            url: "/Sedekah",
            name: "Sedekah",
          },
        ]}
      />

      <div className="flex justify-center py-12 px-3">
        <div className="p-5 md:p-10 w-100 md:w-1/2 min-h-screen bg-grey-50 rounded-lg border border-grey-200 shadow-md">
          <div>
            <div className="text-cyan-500 text-xl font-semibold text-center pb-3">
              {typeof info !== "undefined" ? info?.name : ""}
            </div>
            <img
              className="h-1/3 w-full rounded-md"
              src={typeof info !== "undefined" ? info?.gambar : ""}
              alt={typeof info !== "undefined" ? info?.nama : ""}
              onError={(e) => {
                e.currentTarget.src = MosqueDefault;
              }}
            />
            <div className="font-bold text-2xl text-sky-800 py-3">
              Maklumat Masjid
            </div>
            <div className="text-gray-800 font-semibold mt-3">Alamat</div>
            <div>{typeof info !== "undefined" ? info?.alamat : ""}</div>
            <div className="text-gray-800 font-semibold mt-3">Daerah</div>
            <div>{typeof info !== "undefined" ? info?.daerah : ""}</div>
            <div className="text-gray-800 font-semibold flex justify-items-start gap-3 mt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
              </svg>
              <span>No Tel</span>
            </div>
            <div>{typeof info !== "undefined" ? info?.no_tel : ""}</div>
            <div className="font-bold text-2xl text-sky-800 py-3">Sejarah</div>
            <div>{typeof info !== "undefined" ? info?.sejarah : ""}</div>
            <div className="font-bold text-2xl text-sky-800 py-3">
              Kemudahan
            </div>
            <div>{typeof info !== "undefined" ? info?.kemudahan : ""}</div>
            <div className="flex gap-3 justify-start pt-7 pb-2">
              <Link
                to="/sedekah/form"
                state={{ agency: info?.agency_code, nama: info?.name }}
              >
                <button className="bg-cyan-700 border-sky-700 rounded-md py-2 px-3 text-white hover:bg-cyan-600 shadow-md">
                  Sedekah Sekarang
                </button>
              </Link>

              <Link to="/sedekah">
                <button className="bg-grey-100 border-grey-300 hover:border-grey-400 rounded-md py-2 px-5 text-sky-700 shadow-md">
                  Kembali
                </button>
              </Link>
            </div>
            <div className="font-bold">
              Dengan mengklik "Sedekah sekarang" anda mengesahkan bahawa anda
              memahami dan menerima{" "}
              <Link to="/dasar-privasi">
                <span className="text-blue-800 hover:text-blue-500">
                  {` Dasar Privasi `}
                </span>
              </Link>
              kami.
            </div>
            <div className="font-bold text-2xl text-sky-800 py-7">
              Rakan Strategik
            </div>
            <img
              src={TulusLogo}
              alt=""
              className="w-44 h-16 border border-teal-50 rounded-md px-5 py-2 shadow-md"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SedekahInfo;
