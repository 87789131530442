import React, { Fragment } from "react";
import Banner from "../components/Banner";
import ImageBanner from "../assets/images/banner/dataprivasi.jpg";

const facts = [
  {
    id: 1,
    title: "",
    description:
      "Kenyataan dasar privasi ini menerangkan dasar dan prosedur kami untuk menggunakan dan melindungi maklumat peribadi anda apabila anda melayari tapak web kami. Infaq Selangor Sdn Bhd sangat komited untuk melindungi privasi anda mengikut Akta Perlindungan Data Peribadi Malaysia 2010. Infaq Selangor Sdn Bhd telah mengurus dan mengendalikanweb Infaq Selangor ini. Halaman ini bertujuan untuk memaklumkan kepada pengguna dasar privasi Infaq Selangor Sdn Bhd mengenai pengumpulan dan penggunaan maklumat peribadi untuk perkhidmatan kami.Jika anda memilih untuk menggunakan perkhidmatan kami, secara tidak langsung anda telah bersetuju dengan terma syarat pengumpulan dan penggunaan data yang digariskan dalam dasar ini. Tujuan pengumpulan maklumat ini adalah semata-mata untuk menambah baik perkhidmatan untuk pelanggan Infaq Selangor Sdn Bhd. Seperti yang diterangkan dalam pernyataan privasi ini, semua maklumat yang dikumpul tidak akan digunakan atau dikongsi d engan mana-mana pihak ketiga.",
  },
  {
    id: 2,
    title: "Pengumpulan dan Penggunaan Maklumat",
    description:
      "Untuk memberikan perkhidmatan yang terbaik, pihak kami mungkin memerlukan pengguna untuk berkongsi maklumat peribadi tidak terhad seperti nama pengguna, foto, nombor telefon dan e-mel. Maklumat sedemikian akan disimpan dan digunakan untuk tujuan yang dinyatakan dalam pernyataan privasi ini.",
  },
  {
    id: 3,
    title: "Data Peribadi",
    description:
      "Ketika anda menggunakan Perkhidmatan kami, kami mungkin akan meminta anda memberikan maklumat peribadi tertentu yang boleh digunakan untuk menghubungi atau mengenal pasti anda (“Data Peribadi”). Kuki (Cookies) adalah fail data yang dihantar ke peranti anda untuk menyemak memori maklumat laman yang dilawati oleh pengguna. Kuki dihantar oleh laman web ke penyemak imbas anda dan disimpan pada peranti anda. Teknologi penjejakan yang digunakan untuk mengumpul dan menjejak maklumat, serta untuk menambah baik dan menganalisis Perkhidmatan kami.Pengguna boleh memilih sama ada untuk menolak semua kuki atau menerima kuki apabila kuki dihantar. Walau bagaimanapun, jika anda enggan menerima kuki, anda mungkin tidak dapat menggunakan bahagian tertentu dalam Perkhidmatan kami.",
  },
  {
    id: 4,
    title: "Log Data",
    description:
      "Adalah dimaklumkan bahawa apabila anda menggunakan perkhidmatan kami, perkhidmatan pihak ketiga akan merekodkan log pengguna seperti masa dan tarikh penggunaan untuk mengenal pasti masalah dengan aplikasi. Protokol Internet (IP), maklumat peranti, versi sistem pengendalian, konfigurasi peranti dan aplikasi semuanya akan disertakan dalam log.",
  },
  {
    id: 5,
    title: "Keselamatan Data",
    description:
      "Kami amat utamakan keselamatan data anda, tetapi perlu diingatkan tiada kaedah penghantaran Internet atau storan elektronik yang benar-benar selamat. Walaupun kami berusaha sedaya upaya untuk melindungi Data Peribadi anda menggunakan cara yang boleh diterima secara komersial, kami tidak dapat menjamin keselamatan yang lengkap. Oleh yang demikian, pihak kami akan berusaha sedaya yang boleh untuk menggunakan kaedah yang memastikan data anda selamat.",
  },
  {
    id: 6,
    title: "Pautan ke Laman Web Lain",
    description:
      "Perkhidmatan kami mungkin mengandungi pautan ke laman web yang tidak dikendalikan oleh kami. Anda akan diarahkan ke laman web pihak ketiga jika anda mengklik pada pautan pihak ketiga. Kami menasihatkan anda membaca Dasar Privasi setiap laman web yang anda lawati. Pihak kami tidak mempunyai kawalan ke atas, dan tidak bertanggungjawab bagi kandungan, dasar privasi atau praktik kepada mana-mana laman web atau perkhidmatan pihak ketiga.",
  },
  {
    id: 7,
    title: "Perubahan pada Dasar Privasi ini",
    description:
      "Pernyataan Privasi ini akan dikemas kini dari semasa ke semasa. Sebagai peringatan, kami menasihati pengguna untuk menyemak halaman ini secara berkala untuk sebarang perubahan pada dasar privasi yang ditetapkan. Pada halaman ini, kami juga akan menyiarkan dan memberitahu pengguna tentang sebarang perubahan pada dasar privasi kami. Sebarang perubahan pada dasar privasi akan berkuat kuasa serta-merta selepas ia disiarkan di halaman ini.",
  },
];

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <Banner
        icon={""}
        title={"Dasar Privasi"}
        content={"Keselamatan data peribadi anda menjadi keutamaan kami"}
        image={ImageBanner}
      />

      <div className="py-16 px-5 md:px-20">
        {facts &&
          facts.map((fact, i) => (
            <div key={i}>
              <div className="text-cyan-500 font-semibold text-2xl">
                {fact.title}
              </div>
              <div className="py-5 text-justify">{fact.description}</div>
            </div>
          ))}
      </div>
    </Fragment>
  );
};

export default PrivacyPolicy;
