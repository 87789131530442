import React from "react";
import { Link } from "react-router-dom";

type BreadCrumbsItemProps = {
  url: string;
  name: string;
};

type BreadCrumbsProps = {
  breadCrumbItems: Array<BreadCrumbsItemProps>;
};

const Breadcrumbs = ({ breadCrumbItems }: BreadCrumbsProps) => {
  return (
    <nav
      className="flex p-2 md:p-3 bg-cyan-900 text-white"
      aria-label="Breadcrumb"
      // style={{ backgroundColor: "#0C5B79", color: "white" }}
    >
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {breadCrumbItems.map((item, index) => (
          <li key={index} className="inline-flex items-center ml-5">
            {index > 0 && (
              <svg
                className="w-3 h-3 text-gray-400 mx-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            )}
            {index === breadCrumbItems.length - 1 ? (
              <span className="ml-1 text-xs md:text-base lg:text-xl font-medium text-yellow-500 md:ml-2 dark:text-gray-400">
                {item.name}
              </span>
            ) : (
              <Link
                to={item.url}
                className="ml-1 text-xs md:text-base lg:text-xl font-medium text-gray-700 hover:text-white md:ml-2 dark:text-gray-400 dark:hover:text-white"
              >
                {item.name}
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
